import React, { useState } from 'react';

import Icon from '@mdi/react';
import { mdiAccount, mdiEye, mdiEyeOff } from '@mdi/js';

import { auth, app, googleProvider, appleProvider } from '../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';

import { BrowserRouter, Routes, Route, useNavigate, useLocation, useParams } from "react-router-dom";

import Google from '../assets/google.png'
import Apple from '../assets/apple.png'

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [signIn, setSignIn] = useState(false);
    const [error, setError] = useState(false);
    const [conditions, setConditions] = useState(false);
    const [formData, setFormData] = useState({
        companyName: '',
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const monthly = location.state;

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleAppleSignIn = () => {
        setLoading(true);
        signInWithPopup(auth, appleProvider)
            .then((result) => {
                // L'utilisateur est connecté avec succès
                const user = result.user;
                console.log(result)
                if (monthly !== null) {
                    if (monthly) {
                        window.location.href = 'https://buy.stripe.com/8wM2b02ZGdiF6xG6oq';
                    } else {
                        window.location.href = 'https://buy.stripe.com/dR6eXM8k00vTcW428b';
                    }
                } else {
                    navigate('/Dashboard')
                }
            })
            .catch((error) => {
                // Une erreur s'est produite lors de la connexion avec Google
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleGoogleSignIn = () => {
        setLoading(true);
        signInWithPopup(auth, googleProvider)
            .then((result) => {
                // L'utilisateur est connecté avec succès
                const user = result.user;
                if (monthly !== null) {
                    if (monthly) {
                        window.location.href = 'https://buy.stripe.com/8wM2b02ZGdiF6xG6oq';
                    } else {
                        window.location.href = 'https://buy.stripe.com/dR6eXM8k00vTcW428b';
                    }
                } else {
                    navigate('/Dashboard')
                }
            })
            .catch((error) => {
                // Une erreur s'est produite lors de la connexion avec Google
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const createAccount = (event) => {
        event.preventDefault();

        //Check
        if (!conditions) {
            setError("Veuillez accepter les conditions générale d'utilisation")
            return false
        }

        if (formData.password != formData.confirmPassword) {
            setError("Vos mots de passe sont différents")
            return false
        }
        // Perform login logic with formData
        createUserWithEmailAndPassword(auth, formData.email, formData.password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log(user)
                if (monthly !== null) {
                    if (monthly) {
                        window.location.href = 'https://buy.stripe.com/8wM2b02ZGdiF6xG6oq';
                    } else {
                        window.location.href = 'https://buy.stripe.com/dR6eXM8k00vTcW428b';
                    }
                } else {
                    navigate('/Dashboard')
                }
            })
            .catch((error) => {
                setError("Email déjà existant")
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(errorCode, errorMessage);
            });
    };

    const Login = (event) => {
        event.preventDefault();
        // Perform login logic with formData
        signInWithEmailAndPassword(auth, formData.email, formData.password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                if (monthly !== null) {
                    if (monthly) {
                        window.location.href = 'https://buy.stripe.com/8wM2b02ZGdiF6xG6oq';
                    } else {
                        window.location.href = 'https://buy.stripe.com/dR6eXM8k00vTcW428b';
                    }
                } else {
                    navigate('/Dashboard')
                }
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(errorCode, errorMessage);
            });
    };

    return (
        <div style={{ height: '100vh', width: '100%' }}>
            <div className='d-flex align-items-center justify-content-center'>
                {signIn ?
                    <form onSubmit={createAccount} className='p-4 rounded-2 bg-dark d-flex flex-column form-log' style={{ gap: "20px", width: '400px', maxWidth: "92%", margin: 20, marginTop: 50 }}>
                        <div className='title font-weight-bold'>
                            S'inscrire
                        </div>
                        <div className='font-16'>
                            Merci de remplir ce formulaire pour créer un compte !
                        </div>
                        <div className='border-top w-100'>
                        </div>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            style={{ fontSize: '18px' }}
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                        <div className='position-relative w-100'>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                style={{ width: '-webkit-fill-available' }}
                                name="password"
                                className='spe'
                                placeholder="Mot de passe"
                                value={formData.password}
                                onChange={handleInputChange}
                                required
                            />
                            <Icon path={!showPassword ? mdiEye : mdiEyeOff} className='position-absolute eye' color="white" size={1} onClick={() => setShowPassword((prev) => !prev)} />
                        </div>
                        <div className='position-relative w-100'>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="confirmPassword"
                                name="confirmPassword"
                                className='spe'
                                style={{ width: '-webkit-fill-available' }}
                                placeholder="Confirmation de mot de passe"
                                value={formData.confirmPassword}
                                onChange={handleInputChange}
                                required
                            />
                            <Icon path={!showPassword ? mdiEye : mdiEyeOff} className='position-absolute eye' color="white" size={1} onClick={() => setShowPassword((prev) => !prev)} />
                        </div>
                        <div className='d-flex flex-row align-items-center'>
                            <input type="checkbox" name="conditions" id="conditions" onChange={() => setConditions(!conditions)} checked={conditions} />
                            <label htmlFor="conditions" className='ml-2 font-14'>
                                J'accepte les <span className='font-weight-bold'>conditions d'utilisation</span> et la <span className='font-weight-bold'>politique de confidentialité</span>
                            </label>
                        </div>
                        {error ?
                            <div className='text-danger'>
                                {error}
                            </div> : null
                        }
                        <button type="submit" className='rounded primary-button mt-1'>S'inscrire</button>
                        <button type="button" className='rounded primary-button text-start d-flex flex-row align-items-center font-18 btn-google' disabled={loading} style={{ background: 'white', color: 'black', fontWeight: 'bold' }} onClick={handleGoogleSignIn}><img className='mr-2 ml-2' src={Google} height={'20px'} alt="" /> Continuer avec google</button>
                        <button type="button" className='rounded primary-button text-start d-flex flex-row align-items-center font-18 btn-google' style={{ background: 'white', color: 'black', fontWeight: 'bold' }} disabled={loading} onClick={handleAppleSignIn}><img className='mr-2 ml-2' src={Apple} height={'20px'} alt="" /> Continuer avec Apple</button>
                        <div className='border-top mt-1 mb-1'></div>
                        <button type="button" className='rounded danger-button' onClick={() => setSignIn(false)}>Se connecter</button>
                    </form> :
                    <form onSubmit={Login} className='p-4 rounded-2 bg-dark d-flex flex-column form-log' style={{ gap: "20px", width: '400px', margin: 40 }}>
                        <div className='title font-weight-bold'>
                            Se connecter
                        </div>
                        <div className='border-top w-100'>

                        </div>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            style={{ fontSize: '18px' }}
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                        <div className='position-relative w-100'>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                style={{ width: '-webkit-fill-available' }}
                                name="password"
                                className='spe'
                                placeholder="Mot de passe"
                                value={formData.password}
                                onChange={handleInputChange}
                                required
                            />
                            <Icon path={!showPassword ? mdiEye : mdiEyeOff} className='position-absolute eye' color="white" size={1} onClick={() => setShowPassword((prev) => !prev)} />
                        </div>
                        <button type="submit" className='rounded primary-button mt-2'>Se connecter</button>
                        <button type="button" className='rounded primary-button text-start d-flex flex-row align-items-center font-18 btn-google' style={{ background: 'white', color: 'black', fontWeight: 'bold' }} disabled={loading} onClick={handleGoogleSignIn}><img className='mr-2 ml-2' src={Google} height={'20px'} alt="" /> Continuer avec google</button>
                        <button type="button" className='rounded primary-button text-start d-flex flex-row align-items-center font-18 btn-google' style={{ background: 'white', color: 'black', fontWeight: 'bold' }} disabled={loading} onClick={handleAppleSignIn}><img className='mr-2 ml-2' src={Apple} height={'20px'} alt="" /> Continuer avec Apple</button>
                        <div className='border-top mt-1 mb-1'></div>
                        <button type="button" className='rounded danger-button' onClick={() => setSignIn(true)}>S'inscrire</button>
                    </form>
                }
            </div>
        </div>
    );
};

export default Login;
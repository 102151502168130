import React, { useState, setState, useEffect, useRef } from "react";

import { auth, db, storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL, listAll, list } from "firebase/storage";
import { getDoc, doc, addDoc, collection, setDoc, getDocs, query, where } from "firebase/firestore";

import Icon from '@mdi/react';
import { mdiAccount, mdiAccountHeart, mdiArrowUpThin, mdiBookEdit, mdiCheckboxMarkedCircle, mdiEmail, mdiEye, mdiEyeOff, mdiFileEdit, mdiFrequentlyAskedQuestions, mdiGift, mdiGoogleMaps, mdiOpenInNew, mdiPhone, mdiStamper, mdiStorefrontOutline, mdiTuneVertical, mdiViewDashboardOutline } from '@mdi/js';

//components
import Navbar from "../components/navbar";

import picHome from '../assets/homePic.png'

//assets
import Playstore from '../assets/playstore.png'
import Apple from '../assets/apple.png'
import Screen2 from '../assets/screen2.png'
import Analitic from '../assets/Analytic.png'
import Target from '../assets/target.png'
import animatedProccess from '../assets/animatedProccess.png'
import arrowRightBottom from '../assets/arrow-right-bottom.png'

import Medal from '../assets/medal.png'
import Stats from '../assets/stats.png'
import Notif from '../assets/notifs.png'
import ArrUp from '../assets/arrUp.png'
import CheckCircle from '../assets/check_circle.png'

//rs
import Facebook from '../assets/rs/f.png'
import Insta from '../assets/rs/in.png'

import Play from '../assets/playstore2.png'
import Apple2 from '../assets/apple2.png'

import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";

import Question from '../components/question'
import Contact from '../components/contact'

const faq = [
    {
        question: "Comment puis-je intégrer la carte de fidélité dématérialisée dans mon enseigne ?",
        reponse: "Il vous suffit de souscrire à un de nos abonnements puis de remplir les informations de votre entreprise."
    },
    {
        question: "Quels sont les avantages pour mon enseigne de participer à la carte de fidélité dématérialisée ?",
        reponse: "La participation à la carte de fidélité dématérialisée peut vous aider à fidéliser votre clientèle en offrant des récompenses attractives. Cela peut également vous permettre de collecter des données sur les habitudes de consommation de vos clients pour améliorer votre service et votre marketing."
    },
    {
        question: "Comment puis-je enregistrer les points de fidélité pour mes clients ?",
        reponse: "En utilisant notre application, appuyez sur 'Qrcode' puis renseignez le nombre de points à créditer et scannez le qrcode votre client."
    },
    {
        question: "Comment puis-je définir les récompenses et avantages offerts aux clients fidèles ?",
        reponse: "Vous pouvez définir les récompenses et avantages offerts aux clients fidèles selon vos propres critères. Dans l'application, vous aurez accès à un tableau de bord où vous pourrez configurer les récompenses en fonction du nombre de points accumulés par les clients."
    },
    {
        question: "Puis-je personnaliser l'apparence de ma carte de fidélité numérique ?",
        reponse: "Oui, vous pouvez personnaliser l'apparence de votre carte de fidélité numérique pour refléter l'identité visuelle de votre enseigne. Vous pourrez télécharger votre logo et choisir les couleurs qui correspondent à votre marque."
    },
    {
        question: "Comment puis-je suivre les données de fidélité de mes clients ?",
        reponse: "Vous aurez accès à un tableau de bord où vous pourrez suivre les données de fidélité de vos clients. Vous pourrez voir le nombre de points accumulés par chaque client, les récompenses qu'ils ont utilisées et d'autres informations utiles pour analyser les habitudes de consommation."
    },
];

const Home = ({ infosUser }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const ancre = location.state;
    const [monthly, setMonthly] = useState(false);
    const [mail, setMail] = useState('');
    const [hideNews, setHideNews] = useState(false);
    const [range, setRange] = useState(200);

    const setNewsletter = () => {
        const good = mail.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        if (good) {
            console.log(good)
            setDoc(doc(db, 'newsletter', mail), { mail }).then(() => {
                setHideNews(true)
            })
        } else {
            alert('Adresse mail invalide')
        }
    }

    useEffect(() => {
        if (ancre) {
            window.scrollTo({
                top: document.getElementById('prix').getBoundingClientRect().top,
                behavior: 'smooth'
            });
        }
    }, [])

    const getBackgroundSize = () => {
        return { backgroundSize: `${((range - 40) * 100) / 1000}% 100%` };
    };

    const scrollWhy = () => {
        window.scrollTo({
            top: document.getElementById('Why').getBoundingClientRect().top,
            behavior: 'smooth'
        });
    }

    const generateValues = () => {
        const values = [];
        for (let i = 0; i <= 1000; i += 100) {
            values.push(i);
        }
        return values;
    };


    return (
        <div className="home">
            <Navbar/>
            <header className="d-flex flex-row align-items-center justify-content-between">
                <div className="w-50">
                    <h1>
                        Récompensez la fidélité, <br />
                        <span>
                            Perfectionnez votre entreprise
                        </span>
                    </h1>
                    <div className="mt-3 mb-3">
                        Configurez votre programme de fidélité en utilisant les paramètres de l'application. Définissez les règles pour gagner des points, les récompenses, les offres spéciales et les notifications à envoyer aux clients.
                    </div>
                    <div className="d-flex flex-row flex-wrap" style={{ gap: 30 }}>
                        <div className="button" onClick={() => navigate(!infosUser ? '/Login' : '/Dashboard')}>
                            Créer ma carte
                        </div>
                        <a href="#demo">
                            <div className="button2 border">
                                Réserver une demo
                            </div>
                        </a>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    <img src={picHome} alt="yolo - carte de fidélité" style={{ maxWidth: 600 }} width={"80%"} />
                </div>
            </header>
            <div className="mb-3 d-flex flex-row ctn-prev" style={{ paddingTop: '10rem' }}>
                <div className="d-flex flex-column justify-content-center">
                    <h2 className="mb-4">
                        Vos futures <span className="highlight">économies</span>
                    </h2>
                    <div style={{ maxWidth: 500 }}>
                    Utilisez notre programme de fidélité pour réduire vos coûts, augmenter votre chiffre d'affaires et réduire votre impact environnemental.
                    </div>
                </div>
                <div className="d-flex flex-column ml-5" style={{ flex: 1, maxWidth: 630, margin: 'auto' }}>
                    <h4 className="mb-1">
                        {Math.round((0.2 * range - 10) * 12)}€ / an
                    </h4>
                    <div>
                        <div className="font-20 mb-5">
                        économisés avec notre programme de fidélité
                        </div>
                        <div className="d-flex flex-column" style={{ gap: 20 }}>
                            <input
                                type="range"
                                min={50}
                                max={1000}
                                onChange={(e) => setRange(e.target.value)}
                                style={getBackgroundSize()}
                                value={range}
                            />
                            <div className="d-flex flex-row justify-content-between ml-1">
                                {generateValues().map((value) => (
                                    <span className="range-text" key={value}>{value}</span>
                                ))}
                            </div>
                        </div>
                        <div className="mt-2 font-20">
                            clients / mois
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-3" style={{ paddingTop: '10rem' }}>
                <h2 className="text-center mb-4">
                    Rejoignez le futur de la <span className="highlight">Fidélité</span>
                </h2>
                <div className="d-flex flex-row position-relative w-100 bg-white rounded-2 stepps">
                    <div className="position-absolute customCircle d-flex align-items-center justify-content-center">
                        <div className="bg-secondary rounded-circle d-flex align-items-center justify-content-center" style={{ width: "80px", height: "80px" }}>
                            <img src={arrowRightBottom} width={20} alt="" />
                        </div>
                        <img src={animatedProccess} className="position-absolute rotaText" style={{ width: 110 }} alt="" />
                    </div>
                    <div className="p-4 stepctn d-flex flex-column">
                        <h3 className="border-bottom">
                            <span className="big-number mr-2">
                                01
                            </span>
                            Inscription
                        </h3>
                        <div className="mt-3 text-secondary ">
                        Créez  un compte sur notre site ou bien sur notre application en tant qu'enseigne.
                        </div>
                    </div>
                    <div className="p-4 stepctn">
                        <h3 className="border-bottom">
                            <span className="big-number mr-2">
                                02
                            </span>
                            Votre carte
                        </h3>
                        <div className="mt-3 text-secondary ">
                        Configurez votre programme de fidélité en suivant les étapes. Paramétrez les coordonnées de votre enseigne, la carte de fidélité associée, ...
                        </div>
                    </div>
                    <div className="p-4 stepctn">
                        <h3 className="border-bottom">
                            <span className="big-number mr-2">
                                03
                            </span>
                            Scannez
                        </h3>
                        <div className="mt-3 text-secondary ">
                        Si ce n'est pas déjà fait, téléchargez l'application Yolo et connectez vous avec les mêmes identifiants. Vous pouvez maintenant utiliser l'application !
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-3" id="fonctionnalites" style={{ paddingTop: '10rem' }}>
                <h2 className="mb-4 text-center">
                    Découvrez les avantages de <span className="highlight">YOLO</span>
                </h2>
                <div className="d-flex flex-column" style={{ gap: 20 }}>
                    <div className="d-flex flex-row testttt" style={{ gap: 20, width: '100%' }}>
                        <div className="p-4 rounded-2 bg-primary d-flex flex-column justify-content-between" style={{ color: 'black', width: '40%' }}>
                            <div>
                                <img src={Target} width={70} alt="target" />
                                <h4>
                                    Gagnez en visibilité
                                </h4>
                            </div>
                            <div className="littleBox">
                                Notre solution est conçue pour vous aider à promouvoir votre entreprise, votre marque ou votre produit de manière efficace et ciblée.
                            </div>
                        </div>
                        <div className="p-4 rounded-2 d-flex flex-column justify-content-between bg-highlight2" style={{ width: '60%' }}>
                            <div>
                                <img src={Medal} width={70} alt="target" />
                                <h4>
                                    Fidélisez vos clients
                                </h4>
                            </div>
                            <div className="littleBox">
                                Renforcez votre relation avec vos clients grâce à notre application de fidélisation personnalisée !
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row testttt" style={{ gap: 20, width: '100%' }}>
                        <div className="p-4 rounded-2 bg-highlight d-flex flex-column justify-content-between" style={{ color: 'black', width: '60%' }}>
                            <div>
                                <img src={Stats} width={70} alt="target" />
                                <h4>
                                    Accédez à vos statistiques
                                </h4>
                            </div>
                            <div className="littleBox">
                                Optimisez votre stratégie marketing grâce à notre application qui fournit des statistiques précieuses sur les activités de vos clients.
                            </div>
                        </div>
                        <div className="p-4 rounded-2 d-flex flex-column justify-content-between bg-primary" style={{ width: '40%', color: 'black' }}>
                            <div>
                                <img src={Notif} width={70} alt="target" />
                                <h4>
                                    Envoyez des notifications
                                </h4>
                            </div>
                            <div className="littleBox">
                                Augmentez vos ventes en envoyant des notifications ciblées et personnalisées à vos clients.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-3 d-flex flex-row" style={{ paddingTop: '10rem' }} id='demo'>
                <img src={Analitic} width={'50%'} style={{ maxHeight: '100vh' }} alt="" />
                <div className="ml-5 d-flex flex-column justify-content-center" style={{ gap: 40 }}>
                    <h2>
                        TAUX DE CROISSANCE DU <span className="highlight">PROGRAMME DE FIDÉLITÉ</span>
                    </h2>
                    <div style={{ maxWidth: 600 }}>
                        Notre application vous permettra de suivre la croissance de votre programme de fidélité. En suivant le nombre de nouveaux clients qui rejoignent votre programme chaque mois ou chaque année, vous pourrez identifier les tendances et les changements dans la façon dont les clients interagissent avec votre entreprise.
                        <br /><br /><br />
                        Avec ces informations précieuses, vous pourrez adapter votre programme pour répondre aux besoins de vos clients et maximiser votre chiffre d'affaires.
                    </div>
                    <a href="https://calendar.app.google/WxoZEVB6YueHzznf6" target="blank">
                        <div className="button">
                            Réserver un créneau <Icon path={mdiOpenInNew} className='' color="black" style={{ width: '1vw' }} />
                        </div>
                    </a>
                </div>
            </div>
            <div className="pb-5 m-auto" id="tarif" style={{ paddingTop: '10rem', marginBottom: '5rem', maxWidth: '100%' }}>
                <h2 className="text-center mb-4">
                    Nos <span className="highlight">tarifs</span>
                </h2>
                <div className="d-flex flex-row justify-content-center ctn-pricing" style={{ gap: 50 }}>
                    <div className="bg-primary" style={{ borderRadius: '1rem', color: 'black', width: 500 }}>
                        <h5 className="p-3 pl-4 bg-highlight" style={{ borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', fontSize: 55 }}>
                            Mensuel
                        </h5>
                        <div className="p-4 d-flex flex-column align-items-start p-pricng" style={{ gap: 30 }}>
                            <div className="d-flex align-items-center font-22" style={{ gap: 10 }}>
                                <h5 style={{ fontSize: 50 }}>14.99€</h5> / mois
                            </div>
                            <div style={{ fontSize: 26 }}>
                                Ce qui est inclus
                            </div>
                            <div className="d-flex flex-column align-items-start" style={{ gap: 15 }}>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={CheckCircle} className="mr-2" width={30} />
                                    <div className="font-18 reduceMob">
                                        Créez votre carte de fidélité sur mesure
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={CheckCircle} className="mr-2" width={30} />
                                    <div className="font-18 reduceMob">
                                        Envoyez des notifications directement sur le smartphone de vos clients à tout moment
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={CheckCircle} className="mr-2" width={30} />
                                    <div className="font-18 reduceMob">
                                         Ajoutez jusqu'à 5 points de vente.
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={CheckCircle} className="mr-2" width={30} />
                                    <div className="font-18 reduceMob">
                                        Accédez aux données de votre enseigne via notre tableau de bord
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={CheckCircle} className="mr-2" width={30} />
                                    <div className="font-18 reduceMob">
                                        Affichez votre page google et facebook
                                    </div>
                                </div>
                            </div>
                            <div className="button mt-3 freetrialButton" onClick={() => navigate(!infosUser ? '/Login' : '/Dashboard')}>
                                Commencer l'essai gratuit
                            </div>
                        </div>
                    </div>
                    <div className="bg-primary" style={{ borderRadius: '1rem', color: 'black', width: 500 }}>
                        <h5 className="p-3 pl-4 bg-highlight2 d-flex flex-row justify-content-between align-items-center" style={{ borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', fontSize: 55, color: 'white' }}>
                            Annuel
                            <div className="font-20 font-weight-bold">
                                    Economisez 33%
                                </div>
                        </h5>
                        <div className="p-4 d-flex flex-column align-items-start p-pricng" style={{ gap: 30 }}>
                            <div className="d-flex align-items-center font-22 w-100 position-relative" style={{ gap: 10 }}>
                                <h5 style={{ fontSize: 50 }}>9.99€</h5> / mois
                            </div>
                            <div style={{ fontSize: 26 }}>
                                Ce qui est inclus
                            </div>
                            <div className="d-flex flex-column align-items-start" style={{ gap: 15 }}>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={CheckCircle} className="mr-2" width={30} />
                                    <div className="font-18 reduceMob">
                                        Créez votre carte de fidélité sur mesure
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={CheckCircle} className="mr-2" width={30} />
                                    <div className="font-18 reduceMob">
                                        Envoyez des notifications directement sur le smartphone de vos clients à tout moment
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={CheckCircle} className="mr-2" width={30} />
                                    <div className="font-18 reduceMob">
                                         Ajoutez jusqu'à 5 points de vente.
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={CheckCircle} className="mr-2" width={30} />
                                    <div className="font-18 reduceMob">
                                        Accédez aux données de votre enseigne via notre tableau de bord
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={CheckCircle} className="mr-2" width={30} />
                                    <div className="font-18 reduceMob">
                                        Affichez votre page google et facebook
                                    </div>
                                </div>
                            </div>
                            <div className="button3 mt-3 freetrialButton" onClick={() => navigate(!infosUser ? '/Login' : '/Dashboard')}>
                                Commencer l'essai gratuit
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pb-5 m-auto" id="faq" style={{ width: 900, paddingTop: '10rem', marginBottom: '7rem', maxWidth: '100%' }}>
                <h2 className="text-center">
                    Questions <span className="highlight">FRéquentes</span>
                </h2>
                <div className='mt-5'>
                    {faq.map((q, i) => (
                        <>
                            <Question no={i + 1} question={q} />
                        </>
                    ))}
                </div>
            </div>

            <div className="pb-5 m-auto d-flex flex-row" id="contact" style={{ paddingTop: '10rem', marginBottom: '10rem', maxWidth: '100%' }}>
                <div className="d-flex flex-column w-50" style={{ gap: 20 }}>
                    <h2>
                        Posez vos questions <span className="highlight">en toute liberté</span>
                    </h2>
                    <div>
                        Envoyez-nous un message et un membre de notre équipe vous répondra rapidement.
                    </div>
                    <hr className="w-100" />
                    <h5 style={{ fontSize: 27 }}>
                        Nous contacter
                    </h5>
                    <div className="d-flex flex-row align-items-center" style={{ gap: 5 }}>
                        <Icon path={mdiGoogleMaps} className='' color="#F2994A" size={1} />
                        <div>
                            Toulouse, France
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center" style={{ gap: 5 }}>
                        <Icon path={mdiPhone} className='' color="#F2994A" size={1} />
                        <div>
                            06 12 10 05 72
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center" style={{ gap: 5 }}>
                        <Icon path={mdiEmail} className='' color="#F2994A" size={1} />
                        <div>
                            contact@yolo-pro.com
                        </div>
                    </div>
                    <a href="mailto:contact@yolo-pro.com" target="_blank" rel="noopener noreferrer">
                        <div className="button mt-3">
                            Contactez nous par mail
                        </div>
                    </a>
                </div>
                <div className="w-50 p-3 d-flex align-items-center justify-content-center">
                    <Contact />
                </div>
            </div>

            <div className="pb-5 m-auto" id="faq" style={{ width: 900, marginBottom: '10rem', maxWidth: '100%' }}>
                <div className="button3 p-3 w-auto" style={{ textTransform: 'uppercase', fontSize: '5vw', textAlign: 'center', marginBottom: 10 }} onClick={() => navigate(!infosUser ? '/Login' : '/Dashboard')}>
                    Commencer
                </div>
                <div className="d-flex flex-row w-100 flex-wrap" style={{ gap: 10 }}>
                    <a href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2s6SdU67tWsGiQiKACCLjbFqb01BZXBn9X0ajdG7N0C3eJiJctENc2oCI1rWHcp-koII2ZfTTc" className="w-50 d-flex" target="_blank" rel="noopener noreferrer">
                        <div className="button2 p-3 border" style={{ textTransform: 'uppercase', fontSize: '3vw', textAlign: 'center', flex: 1 }}>
                            Demander une demo <Icon path={mdiOpenInNew} className='' color="white" style={{ width: '2vw' }} />
                        </div>
                    </a>
                    <a href="#contact" className="d-flex" style={{ flex: 1 }} rel="noopener noreferrer">
                        <div className="button2 p-3 border d-flex align-items-center justify-content-center" style={{ textTransform: 'uppercase', fontSize: '3vw', textAlign: 'center', flex: 1 }}>
                            Nous contacter
                        </div>
                    </a>
                </div>
            </div>

            <div className="bg-highlight2 rounded-2 p-4 d-flex flex-row ctn-stores">
                <div className="d-flex flex-column" style={{ gap: 30, flex: 1 }}>
                    <h2>
                        Commencez dès maintenant
                    </h2>
                    <div>
                        Téléchargez dès maintenant Yolo, pour prendre en main l'application.
                    </div>
                    <div className="d-flex flex-row flex-wrap" style={{ gap: 20 }}>
                        <a href="https://play.google.com/store/apps/details?id=com.gwendish.loyality" target="_blank" rel="noopener noreferrer">
                            <div className="button btn-white2 d-flex align-items-center" style={{ gap: 10 }}>
                                <img src={Play} width={20} alt="" />
                                Google Play
                            </div>
                        </a>
                        <a href="https://apps.apple.com/fr/app/yolo-carte-de-fid%C3%A9lit%C3%A9/id6449986824" target="_blank" rel="noopener noreferrer">
                            <div className="button btn-white2 d-flex align-items-center" style={{ gap: 10 }}>
                                <img src={Apple2} width={20} alt="" />
                                App store
                            </div>
                        </a>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center ctnphone2" style={{ flex: 1, marginTop: -100 }}>
                    <img src={Screen2} className="phone2" height={400} />
                </div>
            </div>
            <footer style={{ marginTop: '10rem', marginBottom: 30 }}>
                <div className="d-flex justify-content-between">
                    <div className="d-flex flex-row">
                        <a href="#">
                            <div className="goUp">
                                <img src={ArrUp} alt="" />
                            </div>
                        </a>
                        <div className="d-flex flex-column ml-3" style={{ gap: 20 }}>
                            <div className="d-flex flex-row" style={{ gap: 10 }}>
                                <a href="https://www.instagram.com/yolo.entreprise/" target="_blank" rel="noopener noreferrer">
                                    <div className="rounded-circle rsicone d-flex align-items-center justify-content-center">
                                        <img src={Insta} width={25} alt="" />
                                    </div>
                                </a>
                                <a href="https://www.facebook.com/profile.php?id=100094363383919" target="_blank" rel="noopener noreferrer">
                                    <div className="rounded-circle rsicone d-flex align-items-center justify-content-center">
                                        <img src={Facebook} width={25} alt="" />
                                    </div>
                                </a>
                            </div>
                            <div>
                                Connexions enrichissantes, Entreprises renforcées
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row flex-wrap routss" style={{ gap: 20, width: 200 }}>
                        <a href="#">
                            <h5 style={{ fontSize: 25 }}>
                                Home
                            </h5>
                        </a>
                        <a href="#">
                            <h5 style={{ fontSize: 25 }}>
                                Fonctionnalités
                            </h5>
                        </a>
                        <a href="#">
                            <h5 style={{ fontSize: 25 }}>
                                Tarifs
                            </h5>
                        </a>
                        <a href="#">
                            <h5 style={{ fontSize: 25 }}>
                                Demo
                            </h5>
                        </a>
                        <a href="#">
                            <h5 style={{ fontSize: 25 }}>
                                Contact
                            </h5>
                        </a>
                    </div>
                </div>
                <hr className="mt-3 mb-3" />
                <div className="d-flex justify-content-between">
                    <div>
                        Termes et conditions
                    </div>
                    <div>
                        Police de confidentialité
                    </div>
                </div>
            </footer>
        </div>
    )
};

export default Home;
import React, { useState } from "react";
import { Chart, Filler, registerables } from "chart.js";
import { Chart as ReactChart, Bar } from "react-chartjs-2";

Chart.register(Filler);
Chart.register(...registerables);

function BarChart({ chartData }) {
  const [userData, setUserData] = useState({
    labels: chartData.map((data) => data.label),
    datasets: [
      {
        label: "Nouveaux clients",
        data: chartData.map((data) => data.count),
        backgroundColor: [
          "rgb(242 190 34 / 10%)",
        ],
        borderColor: "#F2BE22",
        borderWidth: 1,
        hoverBackgroundColor: [
          "#F2BE22",
        ],
      },
      {
        label: "Clients actif",
        data: chartData.map((data) => data.actifCount),
        backgroundColor: [
          "rgb(229 60 213 / 11%)",
        ],
        borderColor: "rgb(229 60 213)",
        borderWidth: 1,
        hoverBackgroundColor: [
          "rgb(229 60 213)",
        ],
      }
      // {
      //   label: "Utilisateurs inactif",
      //   data: chartData.map((data) => data.inactifCount),
      //   backgroundColor: [
      //     "rgb(60 224 229 / 10%)",
      //   ],
      //   borderColor: "#3ce0e5",
      //   borderWidth: 1,
      //   hoverBackgroundColor: [
      //     "#3ce0e5",
      //   ],
      // },
    ],
  });

  const options = {
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        padding: 20, // définir le padding du tooltip
        titleFont: {
          size: 20 // augmenter la font size du titre du tooltip
        },
        bodyFont: {
          size: 14 // augmenter la font size du contenu du tooltip
        }
    }
    },
    scales: {
      x: {
        barPercentage: 0.7,
        categoryPercentage: 0.7,
        ticks: {
          font: {
            size: 14,
          },
          padding: 20, // set the padding between the labels and the chart
        },
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
        },
      },
      y: {
        ticks: {
          font: {
            size: 14,
          },
        },
        grid: {
          display: true,
          color: "rgba(0, 0, 0, 0.15)",
          lineWidth: 1,
          drawBorder: false,
        },
      },
    },
  };

  return <Bar data={userData} options={options} />;
}

export default BarChart;
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import uuid from 'react-uuid';

import { auth, db } from '../firebase';
import { getDoc, doc, addDoc, collection, setDoc, getDocs, query, where } from "firebase/firestore";

import Logo from '../assets/logo/logo.png'


function Confirm({infosUser}) {
  return (
    <div className='d-flex align-items-center justify-content-center w-100' style={{height: '100vh'}}>
        <div>
        <img src={Logo} className="animated-logo" width={150} height={150} alt="" />
        <div className='text-center mt-5 font-weight-bold'>
            Chargement...
        </div>
        </div>
    </div>
  );
}

export default Confirm;
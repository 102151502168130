import React, { useEffect, useState } from "react";

import { auth, db } from '../firebase';
import { getDoc, doc, addDoc, collection, setDoc, getDocs, query, where } from "firebase/firestore";

import Select from 'react-select';

//compontents
import LineChart from "../components/LineChart";
import BarChart from "../components/BarChart";

import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";

import Icon from '@mdi/react';
import { mdiAccount, mdiAccountHeart, mdiEye, mdiEyeOff, mdiFrequentlyAskedQuestions, mdiGift, mdiStamper, mdiStorefrontOutline, mdiTuneVertical, mdiViewDashboardOutline } from '@mdi/js';

import Loading from '../views/Loading'

//img
import Logo from '../assets/logo/logo.png'
import Filigrane from '../assets/filigrane.png'

function Dashboard({ userData, user }) {
    const [registeredUsers, setRegisteredUsers] = useState(null);
    const [countStampp, setCountStamp] = useState(null);
    const [giftCount, setGiftCount] = useState(null);
    const [data, setData] = useState(null);
    const [cumuledData, setCumuledData] = useState(null);
    const [franchiseArray, setFranchiseArray] = useState([]);
    const [selectedType, setSelectedType] = useState(null);

    const navigate = useNavigate();

    const location = useLocation();
    const reload= location.state;

    const monthNames = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre"
    ];

    const testDatas = [
        {
            "month": 5,
            "year": 2022,
            "count": 0,
            "label": "Juin",
            "cumuled": 0,
            "actifCount": 0,
            "inactifCount": 0
        },
        {
            "month": 6,
            "year": 2022,
            "count": 1,
            "label": "Juillet",
            "cumuled": 1,
            "actifCount": 0,
            "inactifCount": 3
        },
        {
            "month": 7,
            "year": 2022,
            "count": 0,
            "label": "Août",
            "cumuled": 1,
            "actifCount": 1,
            "inactifCount": 2
        },
        {
            "month": 8,
            "year": 2022,
            "count": 0,
            "label": "Septembre",
            "cumuled": 1,
            "actifCount": 0,
            "inactifCount": 3
        },
        {
            "month": 9,
            "year": 2022,
            "count": 0,
            "label": "Octobre",
            "cumuled": 1,
            "actifCount": 0,
            "inactifCount": 3
        },
        {
            "month": 10,
            "year": 2022,
            "count": 2,
            "label": "Novembre",
            "cumuled": 3,
            "actifCount": 0,
            "inactifCount": 4
        },
        {
            "month": 11,
            "year": 2022,
            "count": 0,
            "label": "Décembre",
            "cumuled": 3,
            "actifCount": 0,
            "inactifCount": 4
        },
        {
            "month": 0,
            "year": 2023,
            "count": 2,
            "label": "Janvier",
            "cumuled": 5,
            "actifCount": 4,
            "inactifCount": 3
        },
        {
            "month": 1,
            "year": 2023,
            "count": 0,
            "label": "Février",
            "cumuled": 5,
            "actifCount": 0,
            "inactifCount": 5
        },
        {
            "month": 2,
            "year": 2023,
            "count": 2,
            "label": "Mars",
            "cumuled": 7,
            "actifCount": 0,
            "inactifCount": 5
        },
        {
            "month": 3,
            "year": 2023,
            "count": 0,
            "label": "Avril",
            "cumuled": 7,
            "actifCount": 0,
            "inactifCount": 5
        },
        {
            "month": 4,
            "year": 2023,
            "count": 12,
            "label": "Mai",
            "cumuled": 19,
            "actifCount": 14,
            "inactifCount": 5
        }
    ]

    useEffect(() => {
        if (reload) {
			window.location.href = '/Fiche';
		}
        if (userData && userData?.activated == null) {
            navigate('/Setup')
        } else {
            if (user.isEnseigne) {
                getLast12Months()
            } else {
                setData(testDatas)
            }
        }
    }, [userData, user])

    async function getLast12Months() {
        const months = [];
        const currentDate = new Date();
    
        for (let i = 0; i < 6; i++) {
            const month = currentDate.getMonth();
            const year = currentDate.getFullYear();
    
            months.unshift({ month, year, count: 0, label: monthNames[month], cumuled: 0, actifCount: 0, inactifCount: 0 }); // Ajoute le mois au début de la liste
            currentDate.setMonth(month - 1); // Passe au mois précédent
        }

        //get histo
        await getDoc(doc(db, 'histoEnseigne', userData.id))
        .then((querySnapshot) => {
            const histoEnseigne = querySnapshot.data()
            console.log(histoEnseigne.cumuledUsers)
            const aray = histoEnseigne.franchises

            aray.unshift({value: 'All', label: 'Tous'})
            setFranchiseArray(aray)
            setSelectedType(aray[0])

            setCountStamp(histoEnseigne.cumuledStamp)
            setGiftCount(histoEnseigne.cumuledGift)
            setRegisteredUsers(histoEnseigne.cumuledUsers)
            histoEnseigne.histo.map(histo => {
                months.map((month, i) => {
                    if (month.month == histo.month.month && month.year == histo.month.year) {
                        month.actifCount = histo.enseignes.reduce((acc, objet) => acc + parseInt(objet.uniqueUser, 10), 0)
                        month.count = histo.enseignes.reduce((acc, objet) => acc + parseInt(objet.newUsers, 10), 0)

                        if (i !== 0) {
                            month.cumuled = months[i - 1].cumuled + month.count
                        } else {
                            month.cumuled = month.count
                        }
                    }
                })
            })
            if (months[months.length - 1].cumuled !== histoEnseigne.cumuledUsers) {
                months.map((month, i) => {
                    month.cumuled += histoEnseigne.cumuledUsers - months[months.length - 1].cumuled
                })
            }
        })
    
        // Use Promise.all to wait for all asynchronous operations to complete
        // await Promise.all(users.map(async (user, i) => {
        //     const month = new Date(user.registration).getMonth()
        //     const year = new Date(user.registration).getFullYear()
    
        //     const exist = months.find(date => date.month === month)
        //     if (exist && exist.year === year) {
        //         exist.count += 1
        //     }
    
        //     months.map((month, i) => {
        //         if (i !== 0) {
        //             month.cumuled = months[i - 1].cumuled + month.count
        //         } else {
        //             month.cumuled = month.cumuled
        //         }
        //     })
            // const docSnapshot = await getDoc(doc(db, 'history', ids[i]));
            // const histo = docSnapshot.data();
    
            // months.map(listMonth => {
            //     if (histo && histo[userData.id] !== undefined) {
            //         histo[userData.id].map((histo, i) => {
            //             const histoMonth = new Date(histo.date).getMonth();
            //             const histoYear = new Date(histo.date).getFullYear();
    
            //             const exist = listMonth.month === histoMonth && listMonth.year === histoYear;
            //             if (exist) {
            //                 listMonth.actifCount += 1
            //             } else if (year <= listMonth.year) {
            //                 if (year == listMonth.year) {
            //                     if (month <= listMonth.month) {
            //                         listMonth.inactifCount += 1
            //                     }
            //                 } else {
            //                     listMonth.inactifCount += 1
            //                 }
            //             }
            //         });
            //     }
            // });
        // }));
    
        // Now, setData after all the asynchronous operations have completed
        setData(months);
    }

    async function getAllUsers() {
        if (userData) {
            // const enseigneId = userData.id;
            // const q = query(collection(db, "users"), where('enseigneArray', 'array-contains', enseigneId));

            // getDocs(q)
            //     .then((querySnapshot) => {
            //         const users = [];
            //         const ids = [];
            //         let countStamp = 0
            //         let countGift = 0
            //         let test = 0

            //         querySnapshot.forEach((docu) => {
            //             const userData = docu.data();
            //             const enseignes = userData.enseignes || []; // S'assurer que le tableau enseignes existe
            //             const hasEnseigne = enseignes.find((enseigne) => enseigne.id === enseigneId);
            //             if (hasEnseigne) {
            //                 // if(userData.enseigneArray) {
            //                 //     if(!userData.enseigneArray.find(ens => ens === enseigneId)) {
            //                 //         userData.enseigneArray.push(enseigneId)
            //                 //     }
            //                 // } else {
            //                 //     userData.enseigneArray = [enseigneId]
            //                 // }
            //                 // setDoc(doc(db, 'users', userData.id), userData)
            //                 //     .then(() => {
            //                 //         console.log('done')
            //                 //     }).catch(err => {
            //                 //         console.log(err)
            //                 //     })

            //                 hasEnseigne?.stampTabs.map(tab => {
            //                     countStamp += tab.cumuledStamp
            //                     countGift += tab.giftCount
            //                 })
            //                 users.push(hasEnseigne);
            //                 ids.push(userData.id);
            //             }
            //         });
            //         setCountStamp(countStamp)
            //         setGiftCount(countGift)
            //         setRegisteredUsers(users)
            //         getLast12Months(users, ids)
            //     })
            //     .catch((error) => {
            //         console.log("Erreur lors de la récupération des utilisateurs : ", error);
            //     });
        } else if (!user.isEnseigne) {
            setData(testDatas)
        }
    }

    return (
        <div className="d-flex flex-row w-100" style={{ minHeight: '100vh', alignItems: 'stretch' }}>
            <div className="d-flex justify-content-center d-flex flex-column dash-margin" style={{ margin: 'auto', marginTop: '50px' }}>
                <div className="title font-weight-bold mb-4 d-flex flex-row justify-content-between align-items-center">
                    Tableau de bord
                    <div>
                        <Select
                            value={selectedType}
                            className="react-select-dark"
                            onChange={setSelectedType}
                            options={franchiseArray}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    primary25: 'black',
                                    primary: 'black',
                                },
                            })}
                        />
                    </div>
                </div>
                <div className="d-flex flex-row flex-wrap" style={{ gap: '30px', flexGrow: 1, width: '-webkit-fill-available' }}>
                    <div className="rounded p-3 d-flex flex-row align-items-center card-2 position-relative" style={{ backgroundColor: '#2A2D3E' }}>
                        {!user?.isEnseigne ?
                            <div className="position-absolute test-overlay rounded" style={{backgroundImage: 'url('+Filigrane+')'}}>
                            </div> : null
                        }
                        <div className="rounded-circle stamped d-flex align-items-center justify-content-center" style={{ backgroundColor: '#2F3751' }}>
                            <Icon path={mdiAccountHeart} className='' color="#5B93FF" size={1.5} />
                        </div>
                        <div className="ml-3 d-flex flex-column">
                            <div className="font-weight-bold font-22">
                                {!user?.isEnseigne ? 45 : registeredUsers && registeredUsers}
                            </div>
                            <div>
                                Clients inscrits
                            </div>
                        </div>
                    </div>
                    <div className="rounded p-3 d-flex flex-row align-items-center card-2 position-relative" style={{ backgroundColor: '#2A2D3E' }}>
                        {!user?.isEnseigne ?
                            <div className="position-absolute test-overlay rounded" style={{backgroundImage: 'url('+Filigrane+')'}}>
                            </div> : null
                        }
                        <div className="rounded-circle stamped d-flex align-items-center justify-content-center" style={{ backgroundColor: '#554F47' }}>
                            <Icon path={mdiGift} className='' color="#FFC327" size={1.5} />
                        </div>
                        <div className="ml-3 d-flex flex-column">
                            <div className="font-weight-bold font-22">
                                {!user?.isEnseigne ? 10 : giftCount}
                            </div>
                            <div>
                                Recompenses récupérés
                            </div>
                        </div>
                    </div>
                    <div className="rounded p-3 d-flex flex-row align-items-center card-2 position-relative" style={{ backgroundColor: '#2A2D3E' }}>
                        {!user?.isEnseigne ?
                            <div className="position-absolute test-overlay rounded" style={{backgroundImage: 'url('+Filigrane+')'}}>
                            </div> : null
                        }
                        <div className="rounded-circle stamped d-flex align-items-center justify-content-center" style={{ backgroundColor: '#3F3742' }}>
                            <Icon path={mdiStamper} className='' color="#FF8F6B" size={1.5} />
                        </div>
                        <div className="ml-3 d-flex flex-column">
                            <div className="font-weight-bold font-22">
                                {!user?.isEnseigne ? 87 : countStampp}
                            </div>
                            <div>
                                Tampons collectés
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5 p-4 rounded position-relative graph" style={{ width: '-webkit-fill-available', backgroundColor: '#2A2D3E' }}>
                    {!user?.isEnseigne ?
                        <div className="position-absolute test-overlay rounded" style={{backgroundImage: 'url('+Filigrane+')'}}>
                    <div className="rounded-pill primary-button w-fit mt-3 p-3"  onClick={() => navigate('/', {state: true})}>
                        Commencer l'essai gratuit
                    </div>
                        </div> : null
                    }
                    <div className="title font-weight-bold mb-3">
                        Evolution de vos Clients
                    </div>
                    {data ?
                        <LineChart chartData={data} />
                        : null}
                </div>
                <div className="mt-3 p-4 rounded mb-5 mt-5 position-relative graph" style={{ width: '-webkit-fill-available', backgroundColor: '#2A2D3E' }}>
                    {!user?.isEnseigne ?
                        <div className="position-absolute test-overlay rounded" style={{backgroundImage: 'url('+Filigrane+')'}}>
                                                <div className="rounded-pill primary-button w-fit mt-3 p-3"  onClick={() => navigate('/', {state: true})}>
                        Commencer l'essai gratuit
                    </div>
                        </div> : null
                    }
                    <div className="title font-weight-bold mb-3">
                        Clients
                    </div>
                    {data ?
                        <BarChart chartData={data} />

                        : null}
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
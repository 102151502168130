import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import uuid from 'react-uuid';

import { auth, db } from '../firebase';
import { getDoc, doc, addDoc, collection, setDoc, getDocs, query, where } from "firebase/firestore";

import Playstore from '../assets/playstore.png'
import Apple from '../assets/apple.png'

import Thanks from '../assets/thanks.svg'

const stripe = require('stripe')('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');


function Confirm({infosUser}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [disabledButton, setDisabledButton] = useState(true);
  const [session, setSession] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const sessionID = urlParams.get('session_id');
    const subscription = urlParams.get('subscription');

    const fetchSession = async () => {
      const sessionData = await stripe.checkout.sessions.retrieve(sessionID);

      setSession(sessionData);

          getDoc(doc(db, 'enseignes', infosUser.id)).then((docu) => {
              console.log('Document ajouté avec succès', docu.data());
              let updateEns = docu.data()  
              console.log(updateEns?.activated !== true && sessionData.customer, updateEns, updateEns?.activated)
              if (updateEns?.activated !== true && sessionData.customer) {
                updateEns.activated = true;
                updateEns.isPremium = true;
                updateEns.stripeSession = sessionData.customer;
  
                setDoc(doc(db, 'enseignes', infosUser.id), updateEns)
                  .then(() => setDisabledButton(false)) 
              } else {
                setDisabledButton(false)
              }
          }).catch((error) => {
              console.error('Erreur lors de l\'ajout du document:', error);
          });
    };

    if (infosUser) {
  
      if (sessionID) {
        fetchSession();
      } 
    }
  }, [infosUser]);

  if (disabledButton) {
    return null
  }

  return (
    <div className='d-flex align-items-center justify-content-center' style={{height: '100vh'}}>
      <div className='d-flex flex-column align-items-center justify-content-center p-4 rounded-2 bg-dark'>
        <img height={300} className='imgConf' src={Thanks} alt="" />
        <div className='font-big mt-4 mb-3'>
          Merci pour votre confiance!
        </div>
        <div className='text-center'>
          Vous pouvez désormais personnaliser votre enseigne
        </div>
        <div className='d-flex flex-column align-items-center'>            
          <div className="rounded-pill primary-button w-fit mt-4 mb-3 pt-2 pb-2 mb-4 font-20" onClick={() => navigate('/Dashboard', {state: true})}>
          C'est parti!
        </div>
          <div className="d-flex playButton store" style={{ gap: '100px' }}>
          <a href="https://play.google.com/store/apps/details?id=com.gwendish.loyality">
              <div className="rounded-pill p-1 d-flex flex-row align-items-center bg-white pl-3 pr-3">
                  <img src={Playstore} width={'20px'} alt="" />
                  <div className="ml-2" style={{ color: 'rgb(53 53 53)' }}>
                      <div className="font-14">
                          Obtenez-la sur
                      </div>
                      <div className="font-weight-bold">
                          Google Play
                      </div>
                  </div>
              </div>
          </a>
          <a href="https://apps.apple.com/fr/app/yolo-carte-de-fid%C3%A9lit%C3%A9/id6449986824" target="_blank">
          <div className="rounded-pill p-1 d-flex flex-row align-items-center bg-white pl-3 pr-3">
              <img src={Apple} width={'20px'} alt="" />
              <div className="ml-2" style={{ color: 'rgb(53 53 53)' }}>
                <div className="font-14">
                  Obtenez-la sur
                </div>
                <div className="font-weight-bold">
                  App Store
                </div>
              </div>
            </div>
          </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Confirm;
import React, { useState } from 'react';
import { mdiEye, mdiEyeOff } from '@mdi/js';
import Icon from '@mdi/react';

import { auth, db } from '../firebase';
import { onAuthStateChanged, signOut } from "firebase/auth";

import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";

import Support from './Support'

function SettingsPage({infosUser, userData}) {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    console.log(userData)

    return (
        <div className="d-flex flex-column w-100" style={{ minHeight: '100vh', alignItems: 'stretch' }}>
            <div className="d-flex justify-content-center d-flex flex-column dash-margin" style={{ margin: 'auto', marginTop: '50px' }}>
                <div className="title font-weight-bold mb-4">
                    Paramètres
                </div>
                <div className='ctn-modifs align-items-start'>
                    {/* <div className='rounded p-3' style={{ backgroundColor: '#2A2D3E', width:  'fit-content' }}>
                        <div className='ctn-newpass'>
                            <div className='title-2'>
                                Modifier mot de passe
                            </div>
                            <div className='position-relative'>
                                <input type={showPassword ? 'text' : 'password'} placeholder='mot de passe actuel' onChange={setPassword} value={password} name="" id="" />
                                <div className='img-right' onClick={toggleShowPassword}>
                                    <Icon path={showPassword ? mdiEye : mdiEyeOff} size={1} />
                                </div>
                            </div>
                            <div className='position-relative'>
                                <input type={showPassword ? 'text' : 'password'} placeholder='nouveau mot de passe' onChange={setPassword2} value={password2} name="" id="" />
                                <div className='img-right' onClick={toggleShowPassword}>
                                    <Icon path={showPassword ? mdiEye : mdiEyeOff} size={1} />
                                </div>
                            </div>
                            <button type="submit" className='rounded primary-button mt-2'>Modifier</button>
                        </div>
                    </div> */}
                    <div className='rounded p-3' style={{ backgroundColor: '#2A2D3E', width:  'fit-content' }}>
                        <div className='ctn-newpass'>
                            <div className='title-2'>
                                Facturation
                            </div>
                            <a href="https://billing.stripe.com/p/login/5kA8zDgXq3mKfkYbII" target='blank'>
                            <button type="submit" className='rounded primary-button mt-2'>Voir mes factures</button>
                            </a>
                        </div>
                    </div>
                    <div className='rounded p-3' style={{ backgroundColor: '#2A2D3E', width:  'fit-content', display: 'flex', flexDirection: 'column', gap: 10 }}>
                        <div>
                        Connecté en tant que <span className='font-weight-bold'>{userData?.name}</span>
                        </div>
                    <button type="submit" className='rounded danger-button mt-2' onClick={() => signOut(auth).then(() => navigate('/'))}>Déconnexion</button>
                    </div>
                </div>
            </div>
            <Support/>
        </div>
    );
}

export default SettingsPage;
import React from 'react';

import Question from '../components/question';

function RestaurantSupport() {

    const faq = [
        {
            question: "Comment puis-je intégrer la carte de fidélité dématérialisée dans mon enseigne ?",
            reponse: "Il vous suffit de souscrire à un de nos abonnements puis de remplir les informations de votre entreprise."
        },
        {
            question: "Quels sont les avantages pour mon enseigne de participer à la carte de fidélité dématérialisée ?",
            reponse: "La participation à la carte de fidélité dématérialisée peut vous aider à fidéliser votre clientèle en offrant des récompenses attractives. Cela peut également vous permettre de collecter des données sur les habitudes de consommation de vos clients pour améliorer votre service et votre marketing."
        },
        {
            question: "Comment puis-je enregistrer les points de fidélité pour mes clients ?",
            reponse: "En utilisant notre application, appuyez sur 'Qrcode' puis renseignez le nombre de points à créditer et scannez le qrcode votre client."
        },
        {
            question: "Comment puis-je définir les récompenses et avantages offerts aux clients fidèles ?",
            reponse: "Vous pouvez définir les récompenses et avantages offerts aux clients fidèles selon vos propres critères. Dans l'application, vous aurez accès à un tableau de bord où vous pourrez configurer les récompenses en fonction du nombre de points accumulés par les clients."
        },
        {
            question: "Puis-je personnaliser l'apparence de ma carte de fidélité numérique ?",
            reponse: "Oui, vous pouvez personnaliser l'apparence de votre carte de fidélité numérique pour refléter l'identité visuelle de votre enseigne. Vous pourrez télécharger votre logo et choisir les couleurs qui correspondent à votre marque."
        },
        {
            question: "Comment puis-je suivre les données de fidélité de mes clients ?",
            reponse: "Vous aurez accès à un tableau de bord où vous pourrez suivre les données de fidélité de vos clients. Vous pourrez voir le nombre de points accumulés par chaque client, les récompenses qu'ils ont utilisées et d'autres informations utiles pour analyser les habitudes de consommation."
        },
    ];

    return (
        <div className="d-flex d-flex flex-column dash-margin" style={{ margin: 'auto', marginTop: '50px' }}>
            <div className="title font-weight-bold mb-4">
                Support
            </div>
            <div className='rounded support' style={{width: 580}}>
                {faq.map((q, i) => {
                    return (
                        <Question no={i + 1} question={q}/>
                    )
                })}
            </div>
            <a href={`mailto:contact@yolo-pro.com`}>
                <button type="submit" className='rounded primary-button mt-4 mb-3'>Contactez-nous</button>
            </a>
        </div>
    );
}

export default RestaurantSupport;
import React from 'react';

const PrivacyPage = () => {
  return (
    <div className='privacy p-4'>
      <h1>Politique de confidentialité</h1>
      <p>Votre vie privée est importante pour nous. Cette politique de confidentialité explique quelles informations nous collectons auprès de vous lorsque vous utilisez notre application/site web, comment nous utilisons ces informations et comment nous les protégeons.</p>

      <h2>Informations que nous collectons</h2>
      <p>Nous collectons les informations suivantes :</p>
      <ul>
        <li>Les informations de contact telles que votre nom, votre adresse e-mail et votre numéro de téléphone.</li>
        <li>Les informations de transaction liées à l'utilisation de notre service, telles que les achats effectués et les récompenses accumulées.</li>
        <li>Les informations démographiques, telles que votre âge, votre sexe et votre lieu de résidence.</li>
      </ul>

      <h2>Utilisation des informations</h2>
      <p>Nous utilisons les informations collectées pour :</p>
      <ul>
        <li>Fournir et personnaliser nos services, y compris la gestion de votre compte et l'affichage des offres personnalisées.</li>
        <li>Améliorer nos produits et services en analysant les données et en recueillant des commentaires.</li>
        <li>Communiquer avec vous par e-mail, téléphone ou messages push pour vous informer des mises à jour, des offres spéciales et des promotions.</li>
      </ul>

      <h2>Protection des informations</h2>
      <p>Nous prenons des mesures pour protéger vos informations personnelles contre toute utilisation, accès ou divulgation non autorisés. Nous utilisons des mesures de sécurité techniques, administratives et physiques pour prévenir toute perte, mauvaise utilisation ou altération de vos informations.</p>

      <h2>Conservation des informations</h2>
      <p>Nous conservons vos informations personnelles aussi longtemps que nécessaire pour fournir nos services et respecter nos obligations légales.</p>

      <h2>Modifications de la politique de confidentialité</h2>
      <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les mises à jour seront publiées sur cette page, et nous vous encourageons à la consulter régulièrement.</p>

      <h2>Nous contacter</h2>
      <p>Si vous avez des questions concernant notre politique de confidentialité, veuillez nous contacter à l'adresse suivante : <a href={`mailto:contact@yolo-pro.com`} className='text-primary'>contact@yolo-pro.com</a>.</p>
    </div>
  );
};

export default PrivacyPage;
import React, { useState } from "react";

import Icon from '@mdi/react';
import { mdiAccount, mdiInformationBox , mdiEye, mdiEyeOff, mdiFrequentlyAskedQuestions, mdiGift, mdiStamper, mdiStorefrontOutline, mdiTuneVertical, mdiViewDashboardOutline } from '@mdi/js';

const InfoPoint = ({content}) => {
    const [hover, setHover] = useState(false);

  return (
    <div className="position-relative" style={{marginLeft: 'auto'}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} >
        <Icon path={mdiInformationBox} style={{cursor: 'pointer'}} color="white" size={1.5} />
        <div className="p-2 bg-white rounded position-absolute" style={{zIndex: 100, right: 0, top: 0, width: 270, display: hover ? 'block' : 'none', color: 'black'}}>
            {content}
        </div>
    </div>
  );
};

export default InfoPoint;
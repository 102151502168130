import React, {useState} from "react";
import {Chart, Filler, registerables } from 'chart.js'
import {Chart as ReactChart, Line} from 'react-chartjs-2'

Chart.register(Filler);
Chart.register(...registerables);

function LineChart({ chartData }) {
    const [userData, setUserData] = useState({
        labels: chartData.map((data) => data.label),
        datasets: [
          {
            label: "Nouveaux clients",
            data: chartData.map((data) => data.cumuled),
            backgroundColor: [
              "rgba(75,192,192,1)",
              "#ecf0f1",
              "#50AF95",
              "#f3ba2f",
              "#2a71d0",
            ],
            fill: true,
            borderColor: (ctx) => {
                const gradient = ctx.chart.ctx.createLinearGradient(0, 0, 800, 0);
                gradient.addColorStop(0, '#F2BE22');
                gradient.addColorStop(1, '#F29727');
                return gradient;
              },
            borderWidth: 4,
            pointRadius: 5,
            pointBackgroundColor: '#EEEEEE',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHitRadius: 20,
            cubicInterpolationMode: 'monotone',
            backgroundColor: (ctx) => {
                const gradient = ctx.chart.ctx.createLinearGradient(0, 0, 400, 0);
                gradient.addColorStop(0, 'rgb(242 190 34 / 10%)');
                gradient.addColorStop(1, 'rgb(242 151 39 / 10%)');
                return gradient;
              },
          },
        ],
      });

      const options = {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            padding: 20, // définir le padding du tooltip
            titleFont: {
              size: 20 // augmenter la font size du titre du tooltip
            },
            bodyFont: {
              size: 14 // augmenter la font size du contenu du tooltip
            }
        }
      },
        scales: {
          x: {
            barPercentage: 0.7,
            categoryPercentage: 0.7,
            ticks: {
              font: {
                size: 14,
              },
              padding: 20, // set the padding between the labels and the chart
            },
            grid: {
                display: false,
              },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10,
            },
          },
        },
      };

  return <Line data={userData} options={options}/>;
}

export default LineChart;
import React, { useState, setState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";

import Logo from '../assets/logo/logo.png'

import { auth, db } from '../firebase';
import { onAuthStateChanged } from "firebase/auth";

const Navbar = () => {
  const navigate = useNavigate();
  const [users, setUser] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        setUser(true)
        console.log('conn')
      } else {
        // User is signed out
        console.log('notconn')
        setUser(false)
      }
    });
  }, [])


  return (
    <nav className="d-flex flex-row align-items-center p-3" style={{ justifyContent: 'space-between' }}>
      <div className="mainMenu" style={{ width: '200px' }}>
        <img src={Logo} className="rounded" height={50} alt="" />
      </div>
      <div className="d-flex flex-column hideOndesk">
        <div className="ml-3 burger-m" style={{ width: '300px' }}>
          <div className={`burger border rounded p-2 ${isOpen ? 'open' : ''}`} onClick={handleClick}>
            <div className="burger-line"></div>
            <div className="burger-line"></div>
            <div className="burger-line"></div>
            <div className="blur-effect rounded"></div>
          </div>
        </div>
        {isOpen && (
          <ul className="d-flex flex-column burger-nav mt-3 position-relative">
            <div className="blur-effect"></div>
            <li>
              <a href="#" onClick={handleClick}>
                Accueil
              </a>
            </li>
            <li>
              <a href="#fonctionnalites" onClick={handleClick}>
                Fonctionnalités
              </a>
            </li>
            <li>
              <a href="#tarif" onClick={handleClick}>
                Tarifs
              </a>
            </li>
            <li>
              <a href="#demo" onClick={handleClick}>
                Demo
              </a>
            </li>
            <li>
              <a href="#contact" onClick={handleClick}>
                Contact
              </a>
            </li>
          </ul>
        )}
      </div>
      <ul className="d-flex mainMenu position-relative">
        <div className="blur-effect"></div>
        <li>
          <a href="#" onClick={handleClick}>
            Accueil
          </a>
        </li>
        <li>
          <a href="#fonctionnalites" onClick={handleClick}>
            Fonctionnalités
          </a>
        </li>
        <li>
          <a href="#tarif" onClick={handleClick}>
            Tarifs
          </a>
        </li>
        <li>
          <a href="#demo" onClick={handleClick}>
            Demo
          </a>
        </li>
        <li>
          <a href="#contact" onClick={handleClick}>
            Contact
          </a>
        </li>
      </ul>
      <div className="text-left" style={{ width: '200px' }}>
        <div className="button mr-3" style={{ float: 'right' }} onClick={() => navigate(users ? '/Dashboard' : '/Login')}>
          Se connecter
        </div>
      </div>
    </nav>
  )
};

export default Navbar;
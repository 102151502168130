import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import uuid from 'react-uuid';

import { auth, db, storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL, listAll, list } from "firebase/storage";
import { getDoc, doc, addDoc, collection, setDoc, getDocs, query, where } from "firebase/firestore";

import { BrowserRouter, Routes, Route, useNavigate, useLocation, useParams } from "react-router-dom";

import GooglePlacesAutocomplete from 'react-google-autocomplete';

import Playstore from '../assets/playstore.png'
import Apple from '../assets/apple.png'

import Loading from "./Loading";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import Info from "../components/info";

import Icon from '@mdi/react';
import { mdiAccount, mdiAccountHeart, mdiBookEdit, mdiEye, mdiEyeOff, mdiFileEdit, mdiFrequentlyAskedQuestions, mdiGift, mdiStamper, mdiStorefrontOutline, mdiTuneVertical, mdiViewDashboardOutline, mdiMenuRight } from '@mdi/js';

const options = [
    { value: 'shopping', label: 'Shopping' },
    { value: 'beauté', label: 'Beauté' },
    { value: "restaurant", label: "Restaurant" },
    { value: "restaurant vegan", label: "Vegan" },
    { value: "restaurant de fast-food", label: "Fast-food" },
    { value: "restaurant de pizzeria", label: "Pizzeria" },
    { value: 'café', label: 'Café' },
    { value: 'autre', label: 'Autre' }
];

import PlaceholderImg from '../assets/placeholderImg.png'

export default function Setup({ userData, infosUser }) {
    const [selectedImage, setSelectedImage] = useState(userData?.banner);
    const [selectedLogo, setSelectedLogo] = useState(userData?.picture);
    const [addresses, setAddresses] = useState(userData?.geocode);
    const [count, setCount] = useState(userData?.neededStamp);
    const [selectedType, setSelectedType] = useState(userData?.type);
    const [name, setName] = useState(userData?.name);
    const [error, setError] = useState(false);
    const [birthdayGift, setBirthdayGift] = useState(userData?.birthdayGift);
    const [tabs, setTabs] = useState(userData?.stampTabs);
    const [isEdit, setIsEdit] = useState(false);
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [nbFranchise, setNbFranchise] = useState(1);
    const navigate = useNavigate();

    const handleImageSelect = (e) => {
        if (e.target.files[0].size < 2048576) {
            setSelectedImage(URL.createObjectURL(e.target.files[0]));
        } else {
            alert("Image trop volumineuse");
        }
    };

    const handleStampSelect = (e, i) => {
        if (e.target.files[0].size < 2048576) {
            let newPic = [...tabs]
            console.log(i, newPic[i])
            newPic[i].stampImage = URL.createObjectURL(e.target.files[0])
            setTabs(newPic)
        } else {
            alert("Image trop volumineuse");
        }
    };

    useEffect(() => {
        setTabs(userData?.stampTabs)
        setCount(userData?.neededStamp);
        setAddresses(userData?.geocode);
        setSelectedImage(userData?.banner);
        setSelectedLogo(userData?.picture);
        setSelectedType(userData?.type);
        setBirthdayGift(userData?.birthdayGift);
        setName(userData?.name);
    }, [userData]);

    const handleImageClick = () => {
        const fileInput = document.querySelector('#banner');
        fileInput.click();
    };

    const handleStampClick = (i) => {
        const fileInput = document.querySelector('#stamp' + i);
        fileInput.click();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file.size < (2097152 * 2)) {
            setSelectedImage(URL.createObjectURL(file));
        } else {
            alert("Image trop volumineuse");
        }
    };

    const handleLogoSelect = (e) => {
        if (e.target.files[0].size < 2048576) {
            setSelectedLogo(URL.createObjectURL(e.target.files[0]));
        } else {
            alert("Image trop volumineuse");
        }
    };

    const handleLogoClick = () => {
        const fileInput = document.querySelector('#logo');
        fileInput.click();
    };

    const handleDropLogo = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file.size < (2097152 * 2)) {
            setSelectedLogo(URL.createObjectURL(file));
        } else {
            alert("Image trop volumineuse");
        }
    };

    const handleDropStamp = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file.size < (2097152 * 2)) {
            setSelectedStamp(URL.createObjectURL(file));
        } else {
            alert("Image trop volumineuse");
        }
    };

    const increment = (i) => {
        let newNeeded = [...tabs]
        newNeeded[i].neededStamp += 1
        setTabs(newNeeded);
    };

    const decrement = (i) => {
        let newNeeded = [...tabs]
        if (newNeeded[i].neededStamp > 0) {
            newNeeded[i].neededStamp -= 1
            setTabs(newNeeded);
        }
    };

    const handleChange = (selectedOption) => {
        setSelectedType(selectedOption);
    };

    const handleAddressChange = (e, i) => {
        const updatedAddresses = [...addresses];
        updatedAddresses[i].label = e.target.value;
        setAddresses(updatedAddresses);
    };

    const handleNumberChange = (e, i) => {
        const updatedAddresses = [...addresses];
        updatedAddresses[i].number = e;
        setAddresses(updatedAddresses);
    };

    const handlePlaceSelect = (place, i) => {
        const updatedAddresses = [...addresses];
        updatedAddresses[i].label = place.target.value
        console.log(updatedAddresses)
        setAddresses(updatedAddresses)
        // Faire quelque chose avec le lieu sélectionné
    };

    async function processTabsAndUploadImages() {
        try {
            const uploadPromises = tabs.map(async (tab) => {
                console.log('ici', tab.stampImage, tab)
                if (tab.stampImage.includes("blob")) {
                    const response = await fetch(tab.stampImage, {
                        mode: 'no-cors'
                    });
                    const blob = await response.blob();
                    const storageRef = ref(storage, userData.id + '/' + uuid());
                    await uploadBytes(storageRef, blob);
                    tab.stampImage = await getDownloadURL(storageRef);
                }
            });

            await Promise.all(uploadPromises); // Attend que toutes les promesses soient résolues
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    const verifNextStep = () => {
        if (step == 1) {
            if (name.length < 4) {
                setError("Nom d'enseigne invalide, 4 caracèteres minimum")
                return false
            } else if (selectedType == "") {
                setError("Choisissez un type pour votre enseigne")
                return false
            }
        } else if (step == 2) {
            if (selectedImage == "") {
                setError("Veuillez séléctionner une bannière")
                return false
            } else if (selectedLogo == "") {
                setError("Veuillez ajouter votre logo")
                return false
            }
        } else if (step == 4) {
            const hasErr = addresses.find(addr => addr.label == "")
            if (hasErr) {
                setError("1 ou plusieurs addresses ne sont pas renseigner")
                return false
            }
        } else if (step == 5) {
            setLoading(true)
            updateEnseigne()
        }
        if (step != 5) {
            setError(false)
            setStep(step + 1)
        }
    }

    const updateEnseigne = async () => {
        const uploadImage = (image, existingImage) => {
            if (image && image !== existingImage) {
                return fetch(image, {
                    mode: 'no-cors'
                })
                    .then((response) => response.blob())
                    .then((blob) => {
                        const storageRef = ref(storage, userData.id + '/' + uuid());
                        return uploadBytes(storageRef, blob).then(() => {
                            return getDownloadURL(storageRef);
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        return null;
                    });
            }
            return null;
        };

        const getPos = async () => {
            const updateAddr = [...addresses];
            await Promise.all(
                updateAddr.map(async (addr) => {
                    let encodedData = encodeURIComponent(addr.label).toString();
                    addr.number = '+' + addr.number
                    const response = await fetch(
                        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedData}&key=${'AIzaSyDObTdl7RjoOZRBteC7wy9A4UsrTC1gRlE'}`
                    );
                    const pos = await response.json();
                    if (pos) {
                        console.log(pos.results[0].geometry.location);
                        addr.lat = pos.results[0].geometry.location.lat;
                        addr.lng = pos.results[0].geometry.location.lng;
                    }
                })
            );
            return updateAddr;
        };

        const newBannerUrl = await uploadImage(selectedImage, userData.banner);
        const newLogoUrl = await uploadImage(selectedLogo, userData.picture);

        const newStamps = await processTabsAndUploadImages()

        if (newStamps) {
            console.log("aasasasass")
            console.log(tabs)
        }
        userData.stampTabs = tabs

        const pos = await getPos();

        // Mise à jour des valeuselers dans userData
        if (newBannerUrl) {
            userData.banner = newBannerUrl;
        }
        if (newLogoUrl) {
            userData.picture = newLogoUrl;
        }

        if (pos) {
            userData.geocode = pos;
            console.log(pos);
        }

        if (typeof selectedType === 'object') {
            userData.type = selectedType.value;
        } else {
            userData.type = selectedType;
        }

        userData.birthdayGift = birthdayGift;
        userData.name = name;
        userData.activated = false;

        setDoc(doc(db, 'enseignes', userData.id), userData)
            .then(() => {
                console.log('maj');
                updateUser()
            })
            .catch((error) => {
                console.error("Erreur lors de l'ajout du document:", error);
            });
    };

    const updateUser = async () => {
        infosUser.firstLogin = false;
        setDoc(doc(db, 'users', infosUser.id), infosUser).then(() => {
            setIsEdit(false);
            setLoading(false)
            setStep(6)
        }).catch((error) => {
            console.error('Erreur lors de l\'ajout du document:', error);
        });
    }

    const okey = () => {
        navigate('/Dashboard')
    }

    if (loading) {
        return (
            <Loading></Loading>
        )
    }

    return (
        <div className='d-flex align-items-center justify-content-center flex-column setupDoc' style={{ minHeight: 'calc(100vh - 80px)', maxWidth: '90%' }}>
            <div className="title font-weight-bold mb-4">
                Votre enseigne
            </div>
            <div className='d-flex flex-column align-items-center justify-content-between rounded-2 bg-dark p-3 position-relative' style={{ minHeight: 480, width: 400, maxWidth: "90%" }}>
                {
                    step == 1 ?
                        <div>
                            <div className='mt-2'>
                                Nom de l'enseigne
                                <div className='position-relative mt-3'>
                                    <input type='text' placeholder='Pizza planet' value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                            </div>
                            <div className='mt-4' style={{ width: 290 }}>
                                Type de l'enseigne
                                <div className='position-relative mt-3'>
                                    <Select
                                        value={selectedType?.value ? selectedType : options.filter(type => type.value == selectedType)}
                                        className="react-select-dark"
                                        onChange={setSelectedType}
                                        options={options}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                primary25: 'black',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                        </div> : step == 2 ?
                            <div>
                                <div className='mt-2' onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
                                    Bannière de l'enseigne
                                    <img
                                        src={selectedImage ? selectedImage : PlaceholderImg}
                                        width={400}
                                        height={180}
                                        className='rounded image-active mt-2'
                                        alt="Selected"
                                        style={{ cursor: 'pointer', objectFit: 'cover' }}
                                        onClick={handleImageClick}
                                    />
                                    <input type="file" accept="image/*" id='banner' style={{ display: 'none' }} onChange={handleImageSelect} />
                                </div>
                                <div className='mt-2 d-flex flex-column' onDrop={handleDropLogo} onDragOver={(e) => e.preventDefault()}>
                                    Logo de l'enseigne
                                    <img
                                        src={selectedLogo ? selectedLogo : PlaceholderImg}
                                        width={100}
                                        height={100}
                                        alt="Selected"
                                        className='rounded image-active mt-2'
                                        style={{ cursor: 'pointer', objectFit: 'cover' }}
                                        onClick={handleLogoClick}
                                    />
                                    <input type="file" accept="image/*" style={{ display: 'none' }} id='logo' onChange={handleLogoSelect} />
                                </div>
                            </div> : step == 3 ?
                                <div className='d-flex flex-column mt-2'>
                                    <div className='d-flex align-items-center'>
                                        Nombre de franchise: <b className='ml-1'>{nbFranchise}</b> <Info content="Le nombre de vos enseignes" />
                                    </div>
                                    <input type="range" className='w-100 mt-3 mb-4' min="1" value={nbFranchise} onChange={(e) => {
                                        setNbFranchise(e.target.value)
                                        let addr = [addresses[0]]
                                        Array.from({ length: e.target.value }).map((object, i) => {
                                            if (i != 0) {
                                                addr[i] = {
                                                    label: '',
                                                    lat: 0,
                                                    lng: 0,
                                                    number: ''
                                                }
                                            }
                                        })
                                        setAddresses(addr)
                                    }} max="5" />
                                    <div className='d-flex align-items-center'>Offre d'anniversaire <Info content="Envoyez une offre spéciale lors de l'anniversaire de vos clients." /></div>
                                    <div className='position-relative mt-3'>
                                        <input type='text' placeholder="-25% sur l'addition" value={birthdayGift} onChange={(e) => setBirthdayGift(e.target.value)} />
                                    </div>
                                </div> : step == 4 ?
                                    <div>
                                        <div className='mt-2'>Adresse(s)</div>
                                        {addresses?.map((geo, i) => {
                                            return (
                                                <div className='position-relative mt-3 d-flex flex-column' style={{ gap: 10 }} key={i}>
                                                    <input type='text' placeholder='Lien de votre page google' value={geo.google ? geo.google : ''} onChange={(e) => {
                                                        geo.google = e.target.value
                                                        const newgo = [...addresses]
                                                        setAddresses(newgo)
                                                        console.log(e.target.value)
                                                    }} />
                                                    <input type='text' placeholder='Lien de votre page facebook' value={geo.facebook ? geo.facebook : ''} onChange={(e) => {
                                                        geo.facebook = e.target.value
                                                        const newgo = [...addresses]
                                                        setAddresses(newgo)
                                                    }} />
                                                    <GooglePlacesAutocomplete
                                                        apiKey="AIzaSyDObTdl7RjoOZRBteC7wy9A4UsrTC1gRlE"
                                                        onSelect={(e) => handlePlaceSelect(e, i)}
                                                        onBlur={(e) => handlePlaceSelect(e, i)}
                                                        defaultValue={geo.label}
                                                        options={{
                                                            types: ['address'],
                                                            componentRestrictions: { country: 'fr' },
                                                        }}
                                                    />
                                                    <PhoneInput
                                                        value={geo.number}
                                                        country={'fr'}
                                                        placeholder={"+33 6 11 18 32 23"}
                                                        disableDropdown={true}
                                                        onChange={phone => handleNumberChange(phone, i)}
                                                        inputProps={{
                                                            name: 'phone',
                                                        }}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div> : step == 5 ?
                                        <div>
                                            <div className='d-flex flex-row align-items-center'>
                                                <div className='w-100'>Carte(s) de fidélité</div>
                                                <button type="submit" className='rounded primary-button ml-2' disabled={loading} onClick={() => {
                                                    let test = [...tabs]; test.push({
                                                        id: uuid(),
                                                        neededStamp: 10,
                                                        stampImage: '',
                                                        stampReward: '',
                                                        tabName: ''
                                                    })
                                                    setTabs(test)
                                                }}>+</button>
                                            </div>
                                            {tabs && tabs.map((tab, i) => {
                                                console.log(tab)
                                                return (
                                                    <div style={{ width: '100%' }} className='mt-3 border-top mb-2'>
                                                        <div>
                                                            <div className='mt-2' style={{ width: '100%' }}>
                                                                <div className='d-flex align-items-center'>
                                                                    Nom de la carte
                                                                    <Info content="Nom de la carte qui sera affiché pour le client" />
                                                                </div>
                                                                <div className='position-relative mt-3'>
                                                                    <input type='text' placeholder='Burger' value={tab.tabName} onChange={(e) => {
                                                                        let newTab = [...tabs]
                                                                        newTab[i].tabName = e.target.value
                                                                        setTabs(newTab)
                                                                    }} />
                                                                </div>
                                                            </div>
                                                            <div className='d-flex flex-row justify-content-between align-items-center mt-2'>
                                                                <div className='d-flex flex-row'>
                                                                    <button className='rounded primary-button' onClick={() => decrement(i)}>-</button>
                                                                    <div className='position-relative'>
                                                                        <input type="number" className='little-n' value={tab.neededStamp} onChange={(e) => {
                                                                            let newTab = [...tabs]
                                                                            newTab[i].neededStamp = e.target.value
                                                                            setTabs(newTab)
                                                                        }} />
                                                                    </div>
                                                                    <button className='rounded primary-button' onClick={() => increment(i)}>+</button>
                                                                </div>
                                                                <div className='ml-3' onDrop={handleDropStamp} onDragOver={(e) => e.preventDefault()}>
                                                                    <img
                                                                        src={tab.stampImage ? tab.stampImage : PlaceholderImg}
                                                                        width={80}
                                                                        height={80}
                                                                        alt="Selected"
                                                                        className='rounded image-active'
                                                                        style={{ cursor: 'pointer', objectFit: 'cover' }}
                                                                        onClick={() => handleStampClick(i)}
                                                                    />
                                                                    <input type="file" accept="image/*" style={{ display: 'none' }} id={'stamp' + i} onChange={(e) => handleStampSelect(e, i)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='mt-2' style={{ width: '100%' }}>
                                                            <div className='d-flex align-items-center'>
                                                                Récompense de fidélité
                                                                <Info content="Choisissez la récompense pour les clients qui ont atteint le nombre de points que vous venez de définir." />
                                                            </div>
                                                            <div className='position-relative mt-3'>
                                                                <input type='text' placeholder='1 pizza' value={tab.stampReward} onChange={(e) => {
                                                                    let newTab = [...tabs]
                                                                    newTab[i].stampReward = e.target.value
                                                                    setTabs(newTab)
                                                                }} />
                                                            </div>
                                                        </div>
                                                        {i !== 0 ?
                                                            <button type="submit" className='rounded danger-button mt-3 w-100' onClick={() => {
                                                                let deleted = [...tabs];
                                                                deleted.splice(i, 1);
                                                                setTabs(deleted)
                                                            }}>Supprimer</button>
                                                            : ''}
                                                    </div>
                                                )
                                            })}
                                        </div> :
                                        <div className='text-center'>
                                            <h3 className='text-center mb-4' style={{color: "white"}}>
                                                Félicitations !
                                            </h3>
                                            Vous pouvez maintenant accéder à votre enseigne sur votre application mobile !
                                            <div className='mt-3'>
                                                Accédez à vos statistiques en cliquant sur le bouton suivant.
                                            </div>
                                            <div>
                                                <div className="d-flex playButton store mt-4 pt-4 justify-content-between" style={{ gap: '15px' }}>
                                                    <a href="https://play.google.com/store/apps/details?id=com.gwendish.loyality">
                                                        <div className="rounded-pill p-1 d-flex flex-row align-items-center bg-white pl-3 pr-3">
                                                            <img src={Playstore} width={'20px'} alt="" />
                                                            <div className="ml-2" style={{ color: 'rgb(53 53 53)' }}>
                                                                <div className="font-14">
                                                                    Obtenez-la sur
                                                                </div>
                                                                <div className="font-weight-bold">
                                                                    Google Play
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a href="https://apps.apple.com/fr/app/yolo-carte-de-fid%C3%A9lit%C3%A9/id6449986824" target="_blank">
                                                        <div className="rounded-pill p-1 d-flex flex-row align-items-center bg-white pl-3 pr-3">
                                                            <img src={Apple} width={'20px'} alt="" />
                                                            <div className="ml-2" style={{ color: 'rgb(53 53 53)' }}>
                                                                <div className="font-14">
                                                                    Obtenez-la sur
                                                                </div>
                                                                <div className="font-weight-bold">
                                                                    App Store
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                }
                {error &&
                    <div className='mt-2 text-danger'>
                        {error}
                    </div>
                }
                <div className='w-100'>
                    {step < 6 ?
                        <>
                            <button type="submit" className='rounded primary-button mt-3 w-100' disabled={loading} onClick={verifNextStep}>Suivant</button>
                            <button type="submit" className='rounded danger-button mt-2 w-100' disabled={step == 1 || loading} onClick={() => setStep(step - 1)}>Precedent</button>
                        </> : <button type="submit" className='rounded primary-button mt-3 w-100' disabled={loading} onClick={okey}>Suivant</button>
                    }
                </div>
            </div>
            <div className='stepper-custom'>
                <div style={{ width: step / 5 * 100 + "%", maxWidth: '100%' }}>

                </div>
            </div>
        </div >
    );
}
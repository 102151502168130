import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import uuid from 'react-uuid';

import { auth, db, storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL, listAll, list } from "firebase/storage";
import { getDoc, doc, addDoc, collection, setDoc, getDocs, query, where } from "firebase/firestore";

import { BrowserRouter, Routes, Route, useNavigate, useLocation, useParams } from "react-router-dom";

import GooglePlacesAutocomplete from 'react-google-autocomplete';

import Info from "../components/info";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import Icon from '@mdi/react';
import { mdiAccount, mdiAccountHeart, mdiBookEdit, mdiEye, mdiEyeOff, mdiFileEdit, mdiFrequentlyAskedQuestions, mdiGift, mdiStamper, mdiStorefrontOutline, mdiTuneVertical, mdiViewDashboardOutline } from '@mdi/js';

const options = [
	{ value: 'shopping', label: 'Shopping' },
	{ value: 'beauté', label: 'Beauté' },
	{ value: "restaurant", label: "Restaurant" },
	{ value: "restaurant vegan", label: "Vegan" },
	{ value: "restaurant de fast-food", label: "Fast-food" },
	{ value: "restaurant de pizzeria", label: "Pizzeria" },
	{ value: 'café', label: 'Café' },
	{ value: 'autre', label: 'Autre' }
];

import PlaceholderImg from '../assets/placeholderImg.png'

export default function Fiche({ userData }) {
	const [selectedImage, setSelectedImage] = useState(userData?.banner);
	const [selectedLogo, setSelectedLogo] = useState(userData?.picture);
	const [selectedStamp, setSelectedStamp] = useState(userData?.imgStamp);
	const [addresses, setAddresses] = useState(userData?.geocode);
	const [count, setCount] = useState(userData?.neededStamp);
	const [selectedType, setSelectedType] = useState(userData?.type);
	const [gifted, setGifted] = useState(userData?.gifted);
	const [name, setName] = useState(userData?.name);
	const [birthdayGift, setBirthdayGift] = useState(userData?.birthdayGift);
	const [isEdit, setIsEdit] = useState(false);
	const [tabs, setTabs] = useState(userData?.stampTabs);

	const handleImageSelect = (e) => {
		if (e.target.files[0].size < 2048576) {
			setSelectedImage(URL.createObjectURL(e.target.files[0]));
		} else {
			alert("Image trop volumineuse");
		}
	};

    const handleStampSelect = (e, i) => {
        if (e.target.files[0].size < 2048576) {
            let newPic = [...tabs]
            console.log(i, newPic[i])
            newPic[i].stampImage = URL.createObjectURL(e.target.files[0])
            setTabs(newPic)
        } else {
            alert("Image trop volumineuse");
        }
    };

	useEffect(() => {
		setTabs(userData?.stampTabs)
		setCount(userData?.neededStamp);
		setAddresses(userData?.geocode);
		setSelectedImage(userData?.banner);
		setSelectedLogo(userData?.picture);
		setSelectedStamp(userData?.imgStamp);
		setSelectedType(userData?.type);
		setGifted(userData?.gifted);
		setBirthdayGift(userData?.birthdayGift);
		setName(userData?.name);
	}, [userData]);

	const handleImageClick = () => {
		const fileInput = document.querySelector('#banner');
		fileInput.click();
	};

    const handleStampClick = (i) => {
        const fileInput = document.querySelector('#stamp' + i);
        fileInput.click();
    };

	const handleDrop = (e) => {
		e.preventDefault();
		const file = e.dataTransfer.files[0];
		if (file.size < (2097152 * 2)) {
			setSelectedImage(URL.createObjectURL(file));
		} else {
			alert("Image trop volumineuse");
		}
	};

	const handleLogoSelect = (e) => {
		if (e.target.files[0].size < (2097152 * 2)) {
			setSelectedLogo(URL.createObjectURL(e.target.files[0]));
		} else {
			alert("Image trop volumineuse");
		}
	};

	const handleLogoClick = () => {
		const fileInput = document.querySelector('#logo');
		fileInput.click();
	};

	const handleDropLogo = (e) => {
		e.preventDefault();
		const file = e.dataTransfer.files[0];
		if (file.size < (2097152 * 2)) {
			setSelectedLogo(URL.createObjectURL(file));
		} else {
			alert("Image trop volumineuse");
		}
	};


	const handleDropStamp = (e) => {
		e.preventDefault();
		// const file = e.dataTransfer.files[0];
		// if (file.size < 2048576) {
		// 	setSelectedStamp(URL.createObjectURL(file));
		// } else {
		// 	alert("Image trop volumineuse");
		// }
	};

	const increment = (i) => {
		let newNeeded = [...tabs]
		newNeeded[i].neededStamp += 1
		setTabs(newNeeded);
	};

	const decrement = (i) => {
		let newNeeded = [...tabs]
		if (newNeeded[i].neededStamp > 0) {
			newNeeded[i].neededStamp -= 1
			setTabs(newNeeded);
		}
	};

	const handleChange = (selectedOption) => {
		setSelectedType(selectedOption);
	};

	const handleAddressChange = (e, i) => {
		const updatedAddresses = [...addresses];
		updatedAddresses[i].label = e.target.value;
		setAddresses(updatedAddresses);
	};

	const handleNumberChange = (e, i) => {
		const updatedAddresses = [...addresses];
		updatedAddresses[i].number = e;
		setAddresses(updatedAddresses);
	};

	const handlePlaceSelect = (place, i) => {
		const updatedAddresses = [...addresses];
		updatedAddresses[i].label = place.target.value
		setAddresses(updatedAddresses)
		// Faire quelque chose avec le lieu sélectionné
	};

	async function processTabsAndUploadImages() {
        try {
            const uploadPromises = tabs.map(async (tab) => {
				console.log(tab.stampImage.includes("blob"))
				if (tab.stampImage.includes("blob")) {
					const response = await fetch(tab.stampImage, {
						mode: 'no-cors'
					  });
					const blob = await response.blob();
					const storageRef = ref(storage, userData.id + '/' + uuid());
					await uploadBytes(storageRef, blob);
					tab.stampImage = await getDownloadURL(storageRef);	
				}
            });

            await Promise.all(uploadPromises); // Attend que toutes les promesses soient résolues
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

	const updateEnseigne = async () => {
		const uploadImage = (image, existingImage) => {
			if (image && image !== existingImage) {
				return fetch(image)
					.then((response) => response.blob())
					.then((blob) => {
						const storageRef = ref(storage, userData.id + '/' + uuid());
						return uploadBytes(storageRef, blob).then(() => {
							return getDownloadURL(storageRef);
						});
					})
					.catch((err) => {
						console.log(err);
						return null;
					});
			}
			return null;
		};

		const getPos = async () => {
			const updateAddr = [...addresses];
			await Promise.all(
				updateAddr.map(async (addr) => {
					let encodedData = encodeURIComponent(addr.label).toString();
					console.log(encodedData);
					const response = await fetch(
						`https://maps.googleapis.com/maps/api/geocode/json?address=${encodedData}&key=${'AIzaSyDObTdl7RjoOZRBteC7wy9A4UsrTC1gRlE'}`
					);
					const pos = await response.json();
					if (pos) {
						console.log(pos.results[0].geometry.location);
						addr.lat = pos.results[0].geometry.location.lat;
						addr.lng = pos.results[0].geometry.location.lng;
					}
				})
			);
			return updateAddr;
		};

		const newBannerUrl = await uploadImage(selectedImage, userData.banner);
		const newLogoUrl = await uploadImage(selectedLogo, userData.picture);

		const pos = await getPos();

		const newStamps = await processTabsAndUploadImages()

        if (newStamps) {
            console.log("aasasasass")
        }
        userData.stampTabs = tabs

		// Mise à jour des valeurs dans userData
		if (newBannerUrl) {
			userData.banner = newBannerUrl;
		}
		if (newLogoUrl) {
			userData.picture = newLogoUrl;
		}

		if (pos) {
			userData.geocode = pos;
			console.log(pos);
		}

		if (typeof selectedType === 'object') {
			userData.type = selectedType.value;
		} else {
			userData.type = selectedType;
		}

		userData.birthdayGift = birthdayGift;
		userData.name = name;

		setDoc(doc(db, 'enseignes', userData.id), userData)
			.then(() => {
				console.log('maj');
				setIsEdit(false);
			})
			.catch((error) => {
				console.error("Erreur lors de l'ajout du document:", error);
			});
	};

	return (
		<div className="d-flex flex-row w-100 mb-5" style={{ minHeight: '100vh', alignItems: 'stretch' }}>
			<div className="d-flex justify-content-center d-flex flex-column position-relative dash-margin" style={{ margin: 'auto', marginTop: '50px' }}>
				<div className='position-relative'>
					<div className='mt-2' onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
						<img
							src={selectedImage ? selectedImage : PlaceholderImg}
							width={400}
							height={180}
							className='rounded image-active'
							alt="Selected"
							style={{ cursor: 'pointer', objectFit: 'cover' }}
							onClick={handleImageClick}
						/>
						<input type="file" accept="image/*" id='banner' style={{ display: 'none' }} onChange={handleImageSelect} />
					</div>
					<div className='mt-2 ml-3 position-absolute' style={{ top: 130 }} onDrop={handleDropLogo} onDragOver={(e) => e.preventDefault()}>
						<img
							src={selectedLogo ? selectedLogo : PlaceholderImg}
							width={100}
							height={100}
							alt="Selected"
							className='rounded image-active'
							style={{ cursor: 'pointer', objectFit: 'cover' }}
							onClick={handleLogoClick}
						/>
						<input type="file" accept="image/*" style={{ display: 'none' }} id='logo' onChange={handleLogoSelect} />
					</div>
				</div>
				<div className='d-flex flex-row flex-wrap mt-5' style={{ gap: 30 }}>
					<div className='rounded p-3' style={{ backgroundColor: '#2A2D3E', width: 'fit-content' }}>
						Nom de l'enseigne
						<div className='position-relative mt-3'>
							<input type='text' placeholder='Pizza planet' value={name} onChange={(e) => setName(e.target.value)} />
						</div>
					</div>
					<div className='rounded p-3' style={{ backgroundColor: '#2A2D3E', width: 290 }}>
						Type de l'enseigne
						<div className='position-relative mt-3'>
							<Select
								value={selectedType?.value ? selectedType : options.filter(type => type.value == selectedType)}
								className="react-select-dark"
								onChange={setSelectedType}
								options={options}
								theme={(theme) => ({
									...theme,
									borderRadius: 0,
									colors: {
										primary25: 'black',
										primary: 'black',
									},
								})}
							/>
						</div>
					</div>
				</div>
				<div className='border-top mt-3 mb-3'></div>
				<div className='ctn-modifs align-items-start'>
					<div className='rounded p-3' style={{ backgroundColor: '#2A2D3E', width: 'fit-content' }}>
						<div className='d-flex align-items-center'>Points <Info content="Choisissez le nombre de points nécessaires pour que vos clients bénéficient d'une récompense." /></div>
						{tabs && tabs.map((tab, i) => {
							return (
								<div style={{ width: '100%' }} className='mt-3 border-top mb-2'>
									<div>
										<div className='mt-2' style={{ width: '100%' }}>
											<div className='d-flex align-items-center'>
												Nom de la carte
												<Info content="Nom de la carte qui sera affiché pour le client" />
											</div>
											<div className='position-relative mt-3'>
												<input type='text' placeholder='Burger' value={tab.tabName} onChange={(e) => {
													let newTab = [...tabs]
													newTab[i].tabName = e.target.value
													setTabs(newTab)
												}} />
											</div>
										</div>
										<div className='d-flex flex-row justify-content-between align-items-center mt-2'>
											<div className='d-flex flex-row'>
												<button className='rounded primary-button' onClick={() => decrement(i)}>-</button>
												<div className='position-relative'>
													<input type="number" className='little-n' value={tab.neededStamp} onChange={(e) => {
														let newTab = [...tabs]
														newTab[i].neededStamp = e.target.value
														setTabs(newTab)
													}} />
												</div>
												<button className='rounded primary-button' onClick={() => increment(i)}>+</button>
											</div>
											<div className='ml-3' onDrop={handleDropStamp} onDragOver={(e) => e.preventDefault()}>
												<img
													src={tab.stampImage ? tab.stampImage : PlaceholderImg}
													width={80}
													height={80}
													alt="Selected"
													className='rounded image-active'
													style={{ cursor: 'pointer', objectFit: 'cover' }}
													onClick={() => handleStampClick(i)}
												/>
												<input type="file" accept="image/*" style={{ display: 'none' }} id={'stamp' + i} onChange={(e) => handleStampSelect(e, i)} />
											</div>
										</div>
									</div>
									<div className='mt-2' style={{ width: '100%' }}>
										<div className='d-flex align-items-center'>
											Récompense de fidélité
											<Info content="Choisissez la récompense pour les clients qui ont atteint le nombre de points que vous venez de définir." />
										</div>
										<div className='position-relative mt-3'>
											<input type='text' placeholder='1 pizza' value={tab.stampReward} onChange={(e) => {
												let newTab = [...tabs]
												newTab[i].stampReward = e.target.value
												setTabs(newTab)
											}} />
										</div>
									</div>
									{i !== 0 ?
										<button type="submit" className='rounded danger-button mt-3 w-100' onClick={() => {
											let deleted = [...tabs];
											deleted.splice(i, 1);
											setTabs(deleted)
										}}>Supprimer</button>
										: ''}
								</div>
							)
						})}
					</div>
					<div className='rounded p-3' style={{ backgroundColor: '#2A2D3E', width: 'fit-content' }}>
						<div className='d-flex align-items-center'>Offre d'anniversaire <Info content="Envoyez une offre spéciale lors de l'anniversaire de vos clients." /></div>
						<div className='position-relative mt-3'>
							<input type='text' placeholder="-25% sur l'addition" value={birthdayGift} onChange={(e) => setBirthdayGift(e.target.value)} />
						</div>
					</div>
					<div className='rounded p-3' style={{ backgroundColor: '#2A2D3E', width: 'fit-content' }}>
						<div>Adresse(s)</div>
						{addresses?.map((geo, i) => {
							return (
								<div className='position-relative mt-3 d-flex flex-column' style={{ gap: 10 }} key={i}>
									<input type='text' placeholder='Lien de votre page google' value={geo.google ? geo.google : ''} onChange={(e) => {
										geo.google = e.target.value
										const newgo = [...addresses]
										setAddresses(newgo)
										console.log(e.target.value)
									}} />
									<input type='text' placeholder='Lien de votre page facebook' value={geo.facebook ? geo.facebook : ''} onChange={(e) => {
										geo.facebook = e.target.value
										const newgo = [...addresses]
										setAddresses(newgo)
									}} />
									<GooglePlacesAutocomplete
										apiKey="AIzaSyDObTdl7RjoOZRBteC7wy9A4UsrTC1gRlE"
										onSelect={(e) => handlePlaceSelect(e, i)}
										onBlur={(e) => handlePlaceSelect(e, i)}
										defaultValue={geo.label}
										options={{
											types: ['address'],
											componentRestrictions: { country: 'fr' },
										}}
									/>
									<PhoneInput
										value={geo.number}
										country={'fr'}
										placeholder={"+33 6 11 18 32 23"}
										disableDropdown={true}
										onChange={phone => handleNumberChange(phone, i)}
										inputProps={{
											name: 'phone',
										}}
									/>
								</div>
							)
						})}
					</div>
				</div>
				{!isEdit ?
					<div className='position-absolute' style={{ right: 0, top: 0 }}>
						<button onClick={() => setIsEdit(true)} className='rounded primary-button d-flex align-items-center justify-content-center' style={{ width: 50, height: 50, padding: 0 }}><Icon path={mdiFileEdit} className='' color="black" size={1} /></button>
					</div> :
					<div className='position-absolute d-flex flex-row' style={{ right: 0, top: 0, gap: 30 }}>
						<button onClick={() => setIsEdit(false)} className='rounded btn-white pl-3 pr-3'>Annuler</button>
						<button onClick={updateEnseigne} className='rounded primary-button'>Sauvegarder</button>
					</div>
				}
			</div>
		</div>
	);
}
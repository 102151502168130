import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

//steel
import './App.scss';
import './index.css';

//views
import Home from './views/Home'
import Login from './views/Login'
import Dashboard from './views/Dashboard'
import Settings from './views/Settings'
import Confirm from './views/Confirm'
import Privacy from './views/Privacy'
import Setup from "./views/Setup";
import Loading from "./views/Loading";
import Subscribe from "./views/Subscribe";
import SubscribeBis from "./views/SubscribeBis";

import { auth, db } from './firebase';
import { onAuthStateChanged } from "firebase/auth";
import { getDoc, doc, setDoc } from "firebase/firestore";
import Fiche from "./views/Fiche";

import Icon from '@mdi/react';
import { mdiMenuRight, mdiStorefront, mdiStorefrontOutline, mdiTuneVertical, mdiViewDashboard, mdiViewDashboardOutline } from '@mdi/js';

//img
import Logo from './assets/logo/logoDark.png'

import uuid from 'react-uuid';

export default function App() {
    const [user, setUser] = useState(null);
    const [uid, setUid] = useState(null);
    const [userData, setUserData] = useState(null);
    const [enseigneData, setEnseigneData] = useState(null);
    const [infosUser, setInfosUser] = useState(null);
    const [showSideBar, setShowSideBar] = useState(true);

    const randomNumber = Math.floor(Math.random() * 1000000);
    const randomSixDigitNumber = randomNumber.toString().padStart(6, '0');


    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                setUid(user.uid)
                console.log("connected", user)
                getUserData(user.uid, user)
                setInfosUser(user.providerData)
                console.log(location.pathname)
                if (location.pathname !== '' && location.pathname !== '/') {
                    // navigate('/Dashboard')
                }
                setUser(true)
            } else {
                // User is signed out
                if (location.pathname !== '' && location.pathname !== '/' && location.pathname !== '/privacy' && location.pathname !== '/Privacy' && !location.pathname.includes("/__/auth")) {
                    navigate('/Login')
                }
                setUid(null)
                setUser(false)
                console.log("disconnected")
            }
        });

        return () => {

        }
    }, [])

    async function getUserData(userId, user) {
        getDoc(doc(db, 'users', userId)).then((doc) => {
            if (doc.exists()) {
                if (!doc.data()?.enseigneId || !doc.data()?.isEnseigne) {
                    CreateUser(userId, user)
                } else {
                    console.log("Document data:", doc.data());
                    setUserData(doc.data())
                    getEnseigneData(doc.data().enseigneId)
                    console.log(doc.data())
                }
            } else {
                console.log("Aucun document trouvé !");
                CreateUser(userId, user)
            }
        }).catch((error) => {
            console.log("Erreur lors de la récupération du document1:", error);
        });
    }

    async function getEnseigneData(enseigneId) {
        getDoc(doc(db, 'enseignes', enseigneId)).then((ens) => {
            if (ens.exists()) {
                setEnseigneData(ens.data())

                if (ens.data()?.activated == null && location.pathname != '' && location.pathname != '/' && location.pathname != '/Setup') {
                    navigate('/Setup')
                } else if (ens.data()?.activated === false && ens.data()?.isPremium === true && location.pathname != '/Subscribe' && location.pathname != '' && location.pathname != '/' && location.pathname !== '/Confirm') {
                    navigate('/Subscribe')
                }

                const expireDate = ens.data().creationDate + (7 * 24 * 60 * 60 * 1000)
                if (expireDate > Date.now() && !ens.data().isPremium) {
                    console.log("included")
                } else {
                    if (!ens.data().isPremium) {
                        const newEns = ens.data()
                        newEns.activated = false
                        newEns.isPremium = true
                        setDoc(doc(db, 'enseignes', newEns.id), newEns).then()
                    }
                }
            } else {
                console.log('cas2')
                setEnseigneData(false)
            }
        })
    }

    async function CreateUser(userId, user) {
        setDoc(doc(db, 'users', userId), {
            name: '',
            lastname: '',
            picture: '',
            id: userId,
            enseigneId: userId,
            mail: user.email,
            birthday: '',
            isEnseigne: true,
            token: [],
            firstLogin: true,
            enseigneArray: [],
            enseignes: []

        }).then(() => {
            console.log('Document ajouté avec succès');

            setDoc(doc(db, 'enseignes', userId), {
                activatedBirth: true,
                banner: '',
                birthdayGift: '',
                creationDate: Date.now(),
                subscription: null,
                geocode: [
                    {
                        label: "",
                        lat: 0,
                        lng: 0
                    }
                ],
                id: userId,
                isPremium: false,
                stampTabs: [
                    {
                        id: uuid(),
                        neededStamp: 10,
                        stampImage: '',
                        stampReward: '',
                        tabName: ''
                    }
                ],
                enseignePass: randomSixDigitNumber,
                name: '',
                picture: '',
                type: ''
            }).then(() => {
                setDoc(doc(db, 'histoEnseigne', userId), {
                    cumuledGift: 0,
                    cumuledStamp: 0,
                    cumuledUsers: 0,
                    franchises: [
                        {
                            cumuledGift: 0,
                            cumuledStamp: 0,
                            cumuledUsers: 0,
                            label: 'ens1'
                        }
                    ],
                    histo: [
                    ]
                }).then(() => {
                    getUserData(userId, user)
                })
            })
        }).catch((error) => {
            console.error('Erreur lors de l\'ajout du document:', error);
        });
    }

    if (location.pathname == '/Dashboard' && !userData && enseigneData == null) {
        return (
            <Loading></Loading>
        )
    }

    if (enseigneData?.isPremium === true && enseigneData?.activated === false && location.pathname != '/Subscribe' && location.pathname != '' && location.pathname != '/' && location.pathname !== '/Confirm') {
        navigate('/Subscribe')
    }

    return (
        <>
            {!enseigneData?.isPremium && enseigneData?.creationDate  && location.pathname !== '/SubscribeBis' && location.pathname !== '/Setup' && location.pathname !== '/Confirm' &&
                <div className=' d-flex align-items-center justify-content-center p-2' onClick={() => navigate('/SubscribeBis')} style={{ position: 'sticky', top: 0, backgroundColor: '#ff8300', cursor: 'pointer', textAlign: 'center', zIndex: 10000, color: 'black' }}>
                    <div>
                        Votre essai gratuit fini dans {Math.round((new Date(enseigneData?.creationDate + (7 * 24 * 60 * 60 * 1000)) - new Date(Date.now())) / 86400000)} jours. <span className='font-weight-bold' style={{ cursor: 'pointer', textDecoration: 'underline' }}>Cliquez ici</span> pour changer d'offre
                    </div>
                </div>
            }
            <div className={location.pathname == '' || location.pathname == '/' ? '' : "d-flex flex-row w-100 justify-content-center"}>
                {user && location.pathname !== '' && location.pathname !== '/' && location.pathname !== '/Login' && location.pathname !== '/Confirm' && location.pathname !== '/Privacy' && location.pathname !== '/privacy' && location.pathname !== "/Setup" && location.pathname !== "/Subscribe" ?
                    <div className={showSideBar ? "sidebar bg-primary d-flex flex-column align-items-center position-relative" : "sidebar bg-primary d-flex flex-column align-items-center position-relative l0"} style={{ height: '100vh', width: showSideBar ? 100 : 300 }}>
                        <div className="position-absolute rounded d-flex align-items-center justify-content-center button-sidebar" onClick={() => setShowSideBar(!showSideBar)} style={{ top: 40, transform: 'translate(-50%, -50%)', left: '100%', width: 40, height: 40, backgroundColor: 'rgb(42, 45, 62)', cursor: 'pointer' }}>
                            <Icon path={mdiMenuRight} style={{ transition: 'all .4s' }} rotate={!showSideBar ? 180 : 0} color="white" size={1.5} />
                        </div>
                        <div className="d-flex align-items-center justify-content-center" style={{ height: 180, width: 240 }}>
                            <img src={Logo} width={70} />
                        </div>
                        {!showSideBar ?
                            <ul className="font-16 font-weight-bold">
                                <li className="d-flex align-items-center p-3 sidabarlink" style={{ backgroundColor: location.pathname == '/Dashboard' ? '#ffffff50' : '' }} onClick={() => { navigate('/Dashboard'); setShowSideBar(!showSideBar); }}><Icon path={location.pathname !== '/Dashboard' ? mdiViewDashboardOutline : mdiViewDashboard} className='mr-2' style={{ minWidth: 40 }} color="black" size={1.5} />Tableau de bord</li>
                                <li className="d-flex align-items-center p-3 sidabarlink" style={{ backgroundColor: location.pathname == '/Fiche' ? '#ffffff50' : '' }} onClick={() => { userData.isEnseigne ? navigate('/Fiche') : null; setShowSideBar(!showSideBar); }}><Icon path={location.pathname !== '/Fiche' ? mdiStorefrontOutline : mdiStorefront} className='mr-2' style={{ minWidth: 40 }} color="black" size={1.5} />Fiche de l'etablissement</li>
                                <li className="d-flex align-items-center p-3 sidabarlink" style={{ backgroundColor: location.pathname == '/Settings' ? '#ffffff50' : '' }} onClick={() => { navigate('/Settings'); setShowSideBar(!showSideBar); }}><Icon path={mdiTuneVertical} className='mr-2' style={{ minWidth: 40 }} color="black" size={1.5} />Paramètres</li>
                            </ul> :
                            <ul className="font-16">
                                <li className="d-flex align-items-center p-3 sidabarlink justify-content-center" style={{ backgroundColor: location.pathname == '/Dashboard' ? '#ffffff50' : '' }} onClick={() => navigate('/Dashboard')}><Icon path={location.pathname !== '/Dashboard' ? mdiViewDashboardOutline : mdiViewDashboard} color="black" size={1.5} /></li>
                                <li className="d-flex align-items-center p-3 sidabarlink justify-content-center" style={{ backgroundColor: location.pathname == '/Fiche' ? '#ffffff50' : '' }} onClick={() => { userData.isEnseigne ? navigate('/Fiche') : null }}><Icon path={location.pathname !== '/Fiche' ? mdiStorefrontOutline : mdiStorefront} color="black" size={1.5} /></li>
                                <li className="d-flex align-items-center p-3 sidabarlink justify-content-center" style={{ backgroundColor: location.pathname == '/Settings' ? '#ffffff50' : '' }} onClick={() => navigate('/Settings')}><Icon path={mdiTuneVertical} color="black" size={1.5} /></li>
                            </ul>
                        }
                        {!userData?.isEnseigne && !showSideBar ?
                            <div className="rounded-pill p-3 font-15 bg-white text-center mt-4 font-weight-bold cursor-pointer btn-white ml-2 mr-2" onClick={() => navigate('/', { state: true })}>Commencer l'essai gratuit</div>
                            : null}
                    </div> : null}
                <Routes>
                    <Route path="/" element={<Home infosUser={userData}></Home>} />
                    <Route path="/Privacy" element={<Privacy userData={enseigneData}></Privacy>} />
                    <Route path="/Login" element={<Login></Login>} />
                    <Route path="/Dashboard" element={<Dashboard userData={enseigneData} user={userData}></Dashboard>} />
                    <Route path="/Settings" element={<Settings infosUser={infosUser} userData={enseigneData}></Settings>} />
                    <Route path="/Confirm" element={<Confirm infosUser={userData}></Confirm>} />
                    <Route path="/Fiche" element={<Fiche userData={enseigneData}></Fiche>} />
                    <Route path="/Setup" element={<Setup infosUser={userData} userData={enseigneData}></Setup>} />
                    <Route path="/Loading" element={<Loading userData={enseigneData}></Loading>} />
                    <Route path="/Subscribe" element={<Subscribe userData={enseigneData}></Subscribe>} />
                    <Route path="/SubscribeBis" element={<SubscribeBis userData={enseigneData}></SubscribeBis>} />
                </Routes>
            </div>
        </>
    );
}
import React, { useState } from 'react';
import uuid from 'react-uuid';
import PhoneInput from 'react-phone-input-2'

import { auth, db } from '../firebase';
import { getDoc, doc, setDoc } from "firebase/firestore";

function App() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    requestDetails: '',
  });
  const [phone, setPhone] = useState('');

  const [sended, setSended] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNumberChange = (e) => {
		const newPhone = e
		setPhone(newPhone);
	};

  const handleSubmit = (e) => {
    e.preventDefault();
    // Ajoutez ici la logique pour envoyer les données du formulaire où vous le souhaitez
    console.log('Formulaire soumis :', formData, phone);
    setDoc(doc(db, 'emailing', uuid()), {
      to: 'contact@yolo-pro.com',
      message: {
          subject: "Demande d'infos",
          html: `
          <div>
          <p>Nom: ${formData.lastName}</p>
          <p>Prénom: ${formData.firstName}</p>
          <p>Téléphone: ${phone}</p>
          <p>Email: ${formData.email}</p>
          <p>Détails de la demande: ${formData.requestDetails}</p>
      </div>
          `
      }
    }).then(() => {
      setSended(true)
    })
  };

  return (
    <div className="container p-3 rounded-2" style={{ width: 500, backgroundColor: "#131313" }}>
      {sended ? 
      <div className='d-flex align-items-center justify-content-center flex-column' style={{height: 500}}>
        <h2 className='text-center'>
          Votre demande à été envoyée!
        </h2>
        <div className='text-center mt-2'>
          Nous vous répondons sous 48h
        </div>
      </div> :
            <form onSubmit={handleSubmit}>
            <div className="mb-2 homeContact">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nom"
                  required
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-2 homeContact">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Prénom"
                  required
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-2 homeContact">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  required
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 homeContact">
              <PhoneInput
                  value={formData.phone}
                  country={'fr'}
                  placeholder={"+33 6 11 18 32 23"}
                  disableDropdown={true}
                  required
                  onChange={val => handleNumberChange(val)}
                  inputProps={{
                    name: 'phone',
                  }}
                />
              </div>
              <div className="mb-4 homeContact">
                <textarea
                  rows="4"
                  className="form-control"
                  placeholder="Détails de la demande"
                  required
                  name="requestDetails"
                  value={formData.requestDetails}
                  onChange={handleChange}
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Envoyer
              </button>
            </form>
      }
    </div>
  );
}

export default App;
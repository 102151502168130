import React, { useState } from 'react';

import { useNavigate } from "react-router-dom"
import CheckCircle from '../assets/check_circle.png'

function SettingsPage({ infosUser, userData }) {
    const navigate = useNavigate();

    return (
        <div className="d-flex flex-column w-100 p-2" style={{ minHeight: '100vh', alignItems: 'stretch' }}>
            <div className='text-center p-3'>
                <h2 className='title font-weight-bold mt-4 mb-4 text-center highlight'>
                    Continuez de Bénéficier des Avantages Exclusifs de YOLO !
                </h2>
                <div className='text-center font-22 mb-4'>
                    Votre essai gratuit de 7 jours s'est Terminé - Abonnez-vous dès Maintenant !
                </div>
            </div>
            <div className='font-20 cla m-auto' style={{width: 1050, maxWidth: '90%'}}>
                Nous espérons que vous avez apprécié votre essai gratuit de 7 jours avec YOLO ! Pour continuer à profiter des avantages exclusifs offerts par notre programme de fidélité, abonnez-vous dès maintenant. Choisissez parmi nos plans abordables et offrez à votre entreprise et à vos clients une expérience exceptionnelle.
            </div>
            <div className="pb-5 m-auto" id="tarif" style={{ paddingTop: '3rem', marginBottom: '5rem', maxWidth: '90%' }}>
                <div className="d-flex flex-row justify-content-center ctn-pricing" style={{ gap: 50 }}>
                    <div className="bg-primary" style={{ borderRadius: '1rem', color: 'black', width: 500 }}>
                        <h5 className="p-3 pl-4 bg-highlight" style={{ borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', fontSize: 55 }}>
                            Mensuel
                        </h5>
                        <div className="p-4 d-flex flex-column align-items-start p-pricng" style={{ gap: 30 }}>
                            <div className="d-flex align-items-center font-22" style={{ gap: 10 }}>
                                <h5 style={{ fontSize: 50 }}>14.99€</h5> / mois
                            </div>
                            <div style={{ fontSize: 26 }}>
                                Ce qui est inclus
                            </div>
                            <div className="d-flex flex-column align-items-start" style={{ gap: 15 }}>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={CheckCircle} className="mr-2" width={30} />
                                    <div className="font-18 reduceMob">
                                        Créez votre carte de fidélité sur mesure
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={CheckCircle} className="mr-2" width={30} />
                                    <div className="font-18 reduceMob">
                                        Envoyez des notifications directement sur le smartphone de vos clients à tout moment
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={CheckCircle} className="mr-2" width={30} />
                                    <div className="font-18 reduceMob">
                                         Ajoutez jusqu'à 5 points de vente.
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={CheckCircle} className="mr-2" width={30} />
                                    <div className="font-18 reduceMob">
                                        Accédez aux données de votre enseigne via notre tableau de bord
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={CheckCircle} className="mr-2" width={30} />
                                    <div className="font-18 reduceMob">
                                        Affichez votre page google et facebook
                                    </div>
                                </div>
                            </div>
                            <a href={'https://buy.stripe.com/8wM2b02ZGdiF6xG6oq'} style={{width: '100%'}}>
                            <div className="button mt-3 freetrialButton">
                                S'abonner
                            </div>
                            </a>
                        </div>
                    </div>
                    <div className="bg-primary" style={{ borderRadius: '1rem', color: 'black', width: 500 }}>
                        <h5 className="p-3 pl-4 bg-highlight2 d-flex flex-row justify-content-between align-items-center" style={{ borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', fontSize: 55, color: 'white' }}>
                            Annuel
                            <div className="font-20 font-weight-bold">
                                    Economisez 33%
                                </div>
                        </h5>
                        <div className="p-4 d-flex flex-column align-items-start p-pricng" style={{ gap: 30 }}>
                            <div className="d-flex align-items-center font-22 w-100 position-relative" style={{ gap: 10 }}>
                                <h5 style={{ fontSize: 50 }}>9.99€</h5> / mois
                            </div>
                            <div style={{ fontSize: 26 }}>
                                Ce qui est inclus
                            </div>
                            <div className="d-flex flex-column align-items-start" style={{ gap: 15 }}>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={CheckCircle} className="mr-2" width={30} />
                                    <div className="font-18 reduceMob">
                                        Créez votre carte de fidélité sur mesure
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={CheckCircle} className="mr-2" width={30} />
                                    <div className="font-18 reduceMob">
                                        Envoyez des notifications directement sur le smartphone de vos clients à tout moment
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={CheckCircle} className="mr-2" width={30} />
                                    <div className="font-18 reduceMob">
                                         Ajoutez jusqu'à 5 points de vente.
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={CheckCircle} className="mr-2" width={30} />
                                    <div className="font-18 reduceMob">
                                        Accédez aux données de votre enseigne via notre tableau de bord
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={CheckCircle} className="mr-2" width={30} />
                                    <div className="font-18 reduceMob">
                                        Affichez votre page google et facebook
                                    </div>
                                </div>
                            </div>
                            <a href={'https://buy.stripe.com/dR6eXM8k00vTcW428b'} style={{width: '100%'}}>
                            <div className="button3 mt-3 freetrialButton">
                                S'abonner
                            </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default SettingsPage;
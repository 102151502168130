import React, { useState } from 'react';

import Icon from '@mdi/react';
import { mdiPlusCircleOutline, mdiMinusCircle } from '@mdi/js';

const Question = ({ question, no }) => {
    const [showAnswer, setShowAnswer] = useState(false);

    const handleClick = () => {
        setShowAnswer(!showAnswer);
    };

    return (
        <div className='d-flex flex-column rounded qq p-2 border mb-2' style={{cursor: 'pointer', borderColor: '#ffffff4a'}} onClick={handleClick}>
            <div className='rounded pb-2 pt-1 d-flex flex-column' style={{ gap: 10, flex: 1 }}>
                <div className='d-flex justify-content-between flex-row align-items-center w-100 font-weight-bold font-20'>
                    <span className='font-18'>{question.question}</span> <span>{showAnswer ? <Icon path={mdiMinusCircle} className="text-highlight" size={1} /> : <Icon path={mdiPlusCircleOutline} className="text-highlight" size={1} />}</span>
                </div>
            </div>
            {showAnswer && <div className='pt-3 pb-1 deroule'>{question.reponse}</div>}
        </div>
    );
};

export default Question;